import React from "react";
import Sidebar from "../Sidebar";

function ViewVender() {
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy text-center">
              <h2>New Vendor Details</h2>
              <div className="student-details-box shadow-box">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tr>
                      <td>
                        <div className="student-img">
                          <img src="assets/images/employe.png" alt="" />
                        </div>
                      </td>
                      <td>
                        <p>
                          <img src="assets/images/add-Vendors.png" alt="" />
                        </p>
                        <p>
                          <b>full name</b>
                        </p>
                        <p>alison</p>
                      </td>
                      <td>
                        <p>
                          <img src="assets/images/add-email.png" alt="" />
                        </p>
                        <p>
                          <b>email address</b>
                        </p>
                        <p>alison.william@gmail.com</p>
                      </td>
                      <td>
                        <p>
                          <img src="assets/images/mobile.png" alt="" />
                        </p>
                        <p>
                          <b>mobile no.</b>
                        </p>
                        <p>+91 09089 08987</p>
                      </td>
                      <td>
                        <p>
                          <img src="assets/images/address.png" alt="" />
                        </p>
                        <p>
                          <b>address</b>
                        </p>
                        <p>
                          45-A, Ansal Colony, New Delhi, Near Central Market
                          Block 2
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className="btn-lv-app cnl-svcng">
                <a href="view_vendor2.html">
                  <button className="btn btn-save-chenge appp mt-2">
                    Approve
                  </button>
                </a>
                <button className="btn btn-cancel mt-2">
                  <img alt="" src="assets/images/reject-button.png" />
                </button>
                <button className="btn btn-cancel mt-2">
                  <img alt="" src="assets/images/delete-button.png" />
                </button>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default ViewVender;
