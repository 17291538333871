import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Constant";

const initialState = {
  list: [],
  userDetail: null,
  totalUser: 0,
  activeUser: 0,
  inActiveUser: 0,
  bookingList: [],
  totalBooking: 0,
  totals: 1,
};

export const GetUserList = createAsyncThunk("userList", async (obj) => {
  const token = await localStorage.getItem("token");
  const response = await axios.get(`${BASE_URL}admin/getUserList`, {
    params: {
      page: obj.page,
      limit: obj.limit,
      search: obj.search,
      status: obj.status,
    },
    headers: {
      token: token,
    },
  });

  return response.data;
});

export const GetUserDetail = createAsyncThunk("userDetail", async (obj) => {
  const token = await localStorage.getItem("token");
  const response = await axios.get(`${BASE_URL}admin/getUserDetails`, {
    params: {
      userId: obj.id,
    },
    headers: {
      token: token,
    },
  });
  return response.data;
});

export const AddNewUser = createAsyncThunk("addUser", async (obj) => {
  const token = await localStorage.getItem("token");

  let formData = new FormData();

  for (let key in obj) {
    formData.append(key, obj[key]);
  }

  const response = await axios.post(`${BASE_URL}admin/addUser`, formData, {
    headers: {
      token: token,
    },
  });
  return response.data;
});

export const DeleteUser = createAsyncThunk("deleteUser", async (obj) => {
  const token = await localStorage.getItem("token");

  const response = await axios.delete(`${BASE_URL}admin/removeUser`, {
    params: {
      userId: obj.id,
    },
    headers: {
      token: token,
    },
  });
  return response.data;
});

export const ToggleUser = createAsyncThunk("toggleUser", async (obj) => {
  const token = await localStorage.getItem("token");

  let formData = new FormData();

  for (let key in obj) {
    formData.append(key, obj[key]);
  }

  const response = await axios.patch(
    `${BASE_URL}admin/updateUserStatus`,
    formData,
    {
      headers: {
        token: token,
      },
    }
  );
  return response.data;
});

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [GetUserList.fulfilled]: (state, action) => {
      state.list = action?.payload?.data?.user;
      state.activeUser = action?.payload?.data?.total_active_user;
      state.inActiveUser = action?.payload?.data?.total_inactive_user;
      state.totalUser = action?.payload?.data?.total_user;
      state.totals =
        action?.payload?.data?.total_user_with_filter !== 0
          ? action?.payload?.data?.total_user_with_filter
          : 1;
    },
    [GetUserDetail.fulfilled]: (state, action) => {
      state.userDetail = action?.payload?.data?.user;
      state.bookingList = action?.payload?.data?.booking_list;
      state.totalBooking = action?.payload?.data?.total_booking;
    },
  },
});

export default UserSlice.reducer;
