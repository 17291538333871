import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  AddVendorAvailabilityCall,
  GetTimeSlot,
  GetWeekDays,
} from "./VenderSlice";
import { useSelector } from "react-redux";
import TimePicker from "react-time-picker";

const availabilityModal = {
  sun: {
    day: "sun",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  mon: {
    day: "mon",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  tue: {
    day: "tue",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  wed: {
    day: "wed",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  thur: {
    day: "thur",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  fri: {
    day: "fri",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  sat: {
    day: "sat",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
};
function AddVenderAvailability() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [dayAvailable, setDayAvailable] = useState(availabilityModal);
  const daySlot = useSelector((state) => state.vender.weekDays);

  const submit = (e) => {
    e.preventDefault();

    let arr = [];
    console.log(dayAvailable);
    // for (const index in dayAvailable) {
    //   if (
    //     dayAvailable[index].open_time !== "" &&
    //     dayAvailable[index].close_time !== "" &&
    //     dayAvailable[index].status !== 0 &&
    //     dayAvailable[index].time_slot.length > 0
    //   ) {
    //     return;
    //   } else {
    //     dayAvailable[index].open_time = "";
    //     dayAvailable[index].close_time = "";
    //     dayAvailable[index].status = 0;
    //     dayAvailable[index].time_slot.length = [];
    //   }
    // }
    // console.log(dayAvailable);

    for (const key in dayAvailable) {
      if (Object.hasOwnProperty.call(dayAvailable, key)) {
        const element = dayAvailable[key];
        arr.push(element);
      }
    }

    console.log(arr);
    let checked = arr.some(
      (item) =>
        item.status === 1 && item.open_time !== "" && item.close_time !== ""
    );
    console.log(checked);
    if (checked) {
      dispatch(
        AddVendorAvailabilityCall({
          available_data: arr,
          vendorId: id,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          navigate(`/add-vender-documents/${id}`, { replace: true });
        } else {
          setError(res?.payload?.message);
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      });
    } else {
      setError("Kindly fill all details.");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  console.log(dayAvailable, "Day available");
  const getTime = useCallback(() => {
    dispatch(GetTimeSlot());
  }, [dispatch]);

  const getDays = useCallback(() => {
    dispatch(GetWeekDays());
  }, [dispatch]);

  const handleTimeSlot = (val, index, cIndex, day) => {
    let arr = { ...dayAvailable };

    let newArr = arr[day].time_slot.map((data, i) => {
      if (i === cIndex) {
        data.time = val.target.value;
      }
      return data;
    });

    setDayAvailable({
      ...dayAvailable,
      [day]: {
        ...dayAvailable[day],
        time_slot: newArr,
      },
    });
  };

  const handleDeleteTime = (pIndex, cIndex, day) => {
    let arr = { ...dayAvailable };

    arr[day].time_slot.map((t, i) => {
      if (i === cIndex) {
        arr[day].time_slot.splice(i, 1);
      }
      return t;
    });

    setDayAvailable(arr);
  };

  const handleChange = (data) => {
    const { value, name, day } = data;
    setDayAvailable({
      ...dayAvailable,
      [day]: {
        ...dayAvailable[day],
        day: day,
        [name]: value,
      },
    });
  };

  useEffect(() => {
    let isRendered = false;
    if (!isRendered) {
      getTime();
      getDays();
    }
    return () => {
      isRendered = false;
    };
  }, [getTime, getDays]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Add a New Vendor</h2>
              {error && <div className="alert alert-danger mt-2">{error}</div>}

              <form className="row form-style-ven">
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <h2>Availability</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <tr>
                        <th>Days</th>
                        <th>Open at</th>
                        <th>Close at</th>
                        <th></th>
                        <th>Add Time Slot</th>
                      </tr>
                      {daySlot?.map((days, i) => {
                        return (
                          <tr key={i}>
                            <td>{days?.day}</td>
                            <td>
                              <input
                                type="time"
                                className="time-drop border-0 shadow rounded-pill p-2"
                                onChange={(val) => {
                                  handleChange({
                                    day: days?.day?.toLowerCase(),
                                    value: val.target.value,
                                    name: "open_time",
                                  });
                                }}
                                aria-describedby="basic-addon1"
                              />
                            </td>
                            <td>
                              <input
                                type="time"
                                className="time-drop border-0 shadow rounded-pill p-2"
                                onChange={(val) => {
                                  handleChange({
                                    day: days?.day?.toLowerCase(),

                                    value: val.target.value,
                                    name: "close_time",
                                  });
                                }}
                                aria-describedby="basic-addon1"
                              />
                            </td>
                            <td>
                              <button type="button" className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle mt-0"
                                  checked={
                                    dayAvailable[days?.day?.toLowerCase()]
                                      ?.status === 1
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleChange({
                                      day: days?.day?.toLowerCase(),
                                      value: e.target.checked === true ? 1 : 0,
                                      name: "status",
                                    });
                                  }}
                                />
                              </button>
                            </td>
                            <td>
                              <div className="form-group col-sm-12 col-md-6 col-lg-6 mt-2">
                                <ul className="services-checkbox time-slot">
                                  {dayAvailable[
                                    days?.day?.toLowerCase()
                                  ]?.time_slot?.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        style={{
                                          position: "relative",
                                          marginRight: "15px",
                                        }}
                                      >
                                      <input
                                      type="time"
                                      className="time-drop border-0 shadow rounded-pill p-2"
                                      value={item.time}
                                      onChange={(val) => {
                                        if (val !== undefined) {
                                          let d = days?.day?.toLowerCase();
                                          handleTimeSlot(val, i, index, d);
                                        }
                                      }}
                                      aria-describedby="basic-addon1"
                                    />
                                        <img
                                          alt=""
                                          src={"assets/images/delete.png"}
                                          className="deleteIcon"
                                          onClick={() => {
                                            handleDeleteTime(
                                              i,
                                              index,
                                              days.day?.toLowerCase()
                                            );
                                          }}
                                        />
                                      </li>
                                    );
                                  })}

                                  <li>
                                    <a
                                      href={() => {
                                        return "a";
                                      }}
                                      onClick={() => {
                                        console.log("Clicked");
                                        let arr = { ...dayAvailable };
                                        arr[
                                          days?.day?.toLowerCase()
                                        ]?.time_slot?.push({
                                          time: "",
                                        });
                                        setDayAvailable(arr);
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src="assets/images/upload.png"
                                      />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="text-center">
                    <span>
                      <button
                        type="button"
                        onClick={(e) => {
                          submit(e);
                        }}
                        className="btn btn-save-chenge mt-2"
                      >
                        Save & Next
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default AddVenderAvailability;
