import React from "react";
import { Modal } from "react-bootstrap";

function ViewServices({ viewShow, setViewShow, data }) {
  const ModalForViewServices = () => {
    return (
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content b-r-30">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={setViewShow}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="text-center mt-5">
              <div className="student-img">
                <img
                  alt=""
                  src={
                    data?.image.includes("http")
                      ? data?.image
                      : "assets/images/default-services.png"
                  }
                  className="img-fluid"
                />
              </div>
              <div className="row mt-4">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                  <h5>Service Name</h5>
                  <p className="text-capitalize">{data?.name}</p>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                  <h5>Sub-Service</h5>
                  <p className="text-capitalize">
                    {data?.sub_service_list?.length}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      show={viewShow}
      onHide={setViewShow}
      dialogAs={ModalForViewServices}
    />
  );
}

export default ViewServices;
