import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  AddVendorServices,
  GetVenderDetail,
  ServiceListForVendor,
} from "./VenderSlice";
import { GetServicesList } from "../Services/ServicesSlice";
import Select from "react-select";

function EditVenderServices() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState("");
  const servicesList = useSelector((state) => state.services.list);
  const [data, setData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [subId, setSubId] = useState([]);
  const [obj, setObj] = useState({});

  const submit = (dataa) => {
    console.log(dataa);
    dispatch(
      AddVendorServices({
        service_data: dataa,
        vendorId: id,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        navigate(`/edit-vender-availability/${id}`);
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const getDatas = (e) => {
    e.preventDefault();
    let datas = [];
    for (const key in selectedOptions) {
      if (Object.hasOwnProperty.call(selectedOptions, key)) {
        const element = selectedOptions[key];
        console.log(element, "element");
        element.data.map((item) => {
          if (item.id) {
            datas.push({
              service_id: item.service_id,
              price: item.price,
              it_service_data: [],
              vendor_service_id: item.id,
              transport_service_data: [],
            });
          } else {
            datas.push({
              service_id: item.service_id,
              price: item.price,
              it_service_data: [],
              transport_service_data: [],
            });
          }
        });
      }
    }
    submit(datas);
  };

  const getList = useCallback(() => {
    dispatch(GetServicesList({ page: "", search: "", limit: "" }));
  }, [dispatch]);

  const handleServiceChanges = (id, e) => {
    let arr = [...subId];

    const { checked } = e.target;
    if (!checked) {
      if (arr.indexOf(id) !== -1) {
        arr.splice(arr.indexOf(id), 1);
        handleSubList(arr);
      }
    } else if (checked) {
      if (arr.indexOf(id) === -1) {
        arr.push(id);
        handleSubList(arr);
      }
    }
    setSubId(arr);
  };

  const handleSubList = useCallback(
    (data) => {
      dispatch(ServiceListForVendor({ service_id: data })).then((res) => {
        if (res?.payload?.code === 1) {
          console.log(res, "Sub service list");
          let d = res?.payload?.data?.service;
          let ob = {};
          d.map((item, i) => {
            ob = {
              ...ob,
              [item.id]: {
                data: item.sub_service_list,
                name: item.name,
                parent_id: item.id,
              },
            };
            return ob;
          });

          setObj(ob);
        }
      });
    },
    [dispatch]
  );

  const getDetails = useCallback(() => {
    dispatch(GetVenderDetail({ id: id })).then((res) => {
      console.log(res, "Get details api ");
      if (res?.payload?.code === 1) {
        setData(res?.payload?.data?.vendor_services);
        let arr = [...subId];
        let d = res?.payload?.data?.vendor_services;
        let obj1 = { ...selectedOptions };

        d?.map((item) => {
          if (arr.indexOf(item.parent_id) === -1) {
            arr.push(item.parent_id);
          }
          return arr;
        });

        d?.map((item) => {
          obj1 = {
            ...obj1,
            [item.parent_id]: {
              data: item.services,
              name: item.parent_name,
              parent_id: item.parent_id,
            },
          };
          return obj1;
        });

        let ob1 = {};
        d.map((item, i) => {
          ob1 = {
            ...ob1,
            [item.parent_id]: {
              data: item.services,
              name: item.parent_name,
              parent_id: item.parent_id,
            },
          };
          return ob1;
        });

        setObj(ob1);

        setSelectedOptions(obj1);

        setSubId(arr);

        handleSubList(arr);
      }
    });
  }, [dispatch, id, handleSubList]);

  const handleSubs = (id, val, itemId) => {
    let arr = selectedOptions[id]?.data?.map((item) => {
      console.log(item, "Itemn");
      if (item.id === itemId) {
        return {
          ...item,
          price: val,
        };
      } else {
        return item;
      }
    });

    setSelectedOptions({
      ...selectedOptions,
      [id]: {
        ...selectedOptions[id],
        data: arr,
      },
    });
  };

  const handleChecked = (id) => {
    let keyArr = Object.keys(obj);
    console.log(keyArr);
    let checked = keyArr.map((item) => {
      return item === id;
    });
    console.log(checked);
    return checked;
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
      getList();
    }
    return () => {
      isRendered = false;
    };
  }, [getDetails, getList]);

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Edit Vendor</h2>
              {error && <div className="alert alert-danger mt-2">{error}</div>}

              <form className="row form-style-ven">
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <h2>Services</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <ul className="services-checkbox">
                    {servicesList && servicesList?.length > 0 ? (
                      servicesList.map((item, i) => {
                        return (
                          <li key={item.id}>
                            <input
                              type="checkbox"
                              id={"myCheckbox1" + item.id}
                              name={item.id}
                              checked={item.id === obj[item.id]?.parent_id}
                              onChange={(e) => {
                                handleServiceChanges(item.id, e);
                              }}
                            />
                            <label htmlFor={`myCheckbox1${item.id}`}>
                              <img
                                alt=""
                                src={
                                  item.image &&
                                  item.image !== null &&
                                  item.image !== "url" &&
                                  item.image !== "" &&
                                  item.image !== "null"
                                    ? item.image
                                    : "assets/images/sub-service.png"
                                }
                              />
                            </label>
                            <h6>{item?.name}</h6>
                          </li>
                        );
                      })
                    ) : (
                      <span>No services available.</span>
                    )}
                  </ul>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <h2>Select Sub-Service</h2>
                </div>
                {subId?.map((item, i) => {
                  return (
                    <div
                      className="form-group col-sm-6 col-md-6 col-lg-6"
                      key={i}
                    >
                      <h5>{obj[item]?.name}</h5>
                      <Select
                        classNamePrefix="react-select"
                        className="form-control"
                        value={selectedOptions[item]?.data}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={obj[item]?.data}
                        placeholder="Select Sub-Services"
                        isMulti={true}
                        onChange={(val) => {
                          if (val !== undefined) {
                            setSelectedOptions({
                              ...selectedOptions,
                              [item]: {
                                data: val,
                                name: obj[item].parent_name,
                              },
                            });
                          } else {
                            setSelectedOptions({
                              ...selectedOptions,
                              [item]: {
                                data: [],
                                name: obj[item].parent_name,
                              },
                            });
                          }
                        }}
                      />

                      <div className="contra">
                        {selectedOptions &&
                          selectedOptions[item]?.data?.map((items, i) => {
                            return (
                              <div
                                className="row align-items-center sub-ser"
                                key={i}
                              >
                                <div className="col-sm-6 col-md-12 col-lg-6 mt-3">
                                  <h6>{items.name}</h6>
                                </div>
                                <div className="col-sm-6 col-md-12 col-lg-6 mt-3 px-4">
                                  <div className="input-container input-group inputGroupText inputGroupTextright shadow">
                                    <span
                                      class="input-group-text border-0 inputGroupText bg-white "
                                      id="basic-addon1"
                                    >
                                      <img
                                        alt=""
                                        src="assets/images/currency.png"
                                        className=""
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Add Price"
                                      value={items.price}
                                      onChange={(e) => {
                                        handleSubs(
                                          item,
                                          e.target.value,
                                          items.id
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}

                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="text-center">
                    <span>
                      <button
                        onClick={(e) => {
                          getDatas(e);
                        }}
                        className="btn btn-save-chenge mt-2"
                      >
                        Save & Next
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default EditVenderServices;
