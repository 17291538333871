// import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoggingIn } from "./SettingSlice";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const submitLogin = () => {
    dispatch(
      LoggingIn({
        email: formik.values.email.trim(),
        password: formik.values.password,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        navigate("/dashboard");
      } else {
        setError(res?.payload?.message);

        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email("Kindly enter a valid email.")
      .required("Email is required."),
    password: yup
      .string()
      .required("No password provided.")
      .min(7, "Password is too short - should be 8 chars minimum."),
    // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);
  return (
    <section className="">
      <div className="container-fluid pl-0 ml-0">
        <div className="row align-items-center">
          <div className="col-sm-6 col-md-6 col-lg-6 text-center ml-0 pl-0 sm-mp-lr">
            <div className="logo-bg">
              <div className="">
                <img
                  src="assets/images/image.png"
                  className="img-fluid w-100"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <form className="row login-form" onSubmit={formik.handleSubmit}>
              <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                <h2 className="mdl-ttl">Log In</h2>
              </div>
              <div className="form-group col-sm-12 col-md-12 mb-4">
                <div className="input-container">
                  <input
                    className="form-control pl-5"
                    placeholder="Your Email Address"
                    name="email"
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      console.log(val);
                      formik.setFieldValue("email", val);
                    }}
                    {...formik.getFieldProps("email")}
                  />
                  <img
                    alt=""
                    src="assets/images/Email.png"
                    className="input-img"
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="alert alert-danger mt-2">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="form-group col-sm-12 col-md-12 mb-4">
                <div className="input-container">
                  <input
                    id="password-field"
                    type={show ? "text" : "password"}
                    className="form-control pl-5"
                    placeholder="Your Password"
                    name="password"
                    onBlur={formik.handleBlur}
                    {...formik.getFieldProps("password")}
                  />
                  <img
                    alt=""
                    src="assets/images/Password.png"
                    className="input-img pass"
                  />
                  <span
                    toggle="#password-field"
                    className="pass-view field-icon toggle-password cursor-pointer"
                    onClick={() => {
                      setShow(!show);
                    }}
                  ></span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className="alert alert-danger mt-2">
                    {formik.errors.password}
                  </div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                <Link to="forgot-password" className="forgot-pass">
                  Forgot Password?
                </Link>
              </div>
              <div className="col-sm-12">
                {error !== "" && (
                  <div className="alert alert-danger mt-2">{error}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <button type="submit" className="btn btn-submit">
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
