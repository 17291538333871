import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useFormik } from "formik";
import * as yup from "yup";
import { AddNewService, GetServicesDetail } from "./ServicesSlice";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../../component/Upload";

function AddServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [image, setImage] = useState("");

  const submitLogin = () => {
    if (image !== "") {
      dispatch(
        AddNewService({
          name: formik.values.serviceName,
          image: image,
          type: formik.values.type,
        })
      ).then((res) => {
        if (res?.payload.code === 1) {
          setError(res.payload.message);
          setTimeout(() => {
            setError("");
            navigate("/manage-services");
          }, 3000);
        }
      });
    } else {
      setError("Kindly select an image.");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };
  const validationSchema = yup.object().shape({
    serviceName: yup.string().required("Required."),
    type: yup.string().required("Required."),
  });
  const formik = useFormik({
    initialValues: {
      serviceName: "",
      type: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });

  const handleImage = (data) => {
    setImage(data.location);
  };

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="services" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Add Service</h2>

              <form
                className="row form-style document-img"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      <img
                        alt=""
                        src={
                          image !== "url" &&
                          image !== null &&
                          image !== "null" &&
                          image !== ""
                            ? image
                            : "assets/images/doc-image.png"
                        }
                      />
                      <p>Upload image</p>
                    </button>
                    <Upload dataHandler={handleImage} />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control pl-5"
                      placeholder="Service Name"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("serviceName")}
                    />
                    <img
                      alt=""
                      src="assets/images/First-Name.png"
                      className="input-img"
                    />
                  </div>
                  {formik.touched.serviceName && formik.errors.serviceName ? (
                    <div className="alert alert-danger mt-2">
                      {formik.errors.serviceName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="input-container">
                    <select
                      className="form-control pl-5"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("type")}
                    >
                      <option value="">Select Service</option>
                      <option value="IT">IT</option>
                      <option value="Transport">Transport</option>
                      <option value="other">Other</option>
                    </select>
                    <img
                      alt=""
                      src="assets/images/First-Name.png"
                      className="input-img"
                    />
                  </div>
                  {formik.touched.type && formik.errors.type ? (
                    <div className="alert alert-danger mt-2">
                      {formik.errors.type}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-save-chenge mt-2"
                      disabled={image === ""}
                    >
                      Save
                    </button>
                    {error !== "" && (
                      <div className="alert alert-danger mt-2">{error}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default AddServices;
