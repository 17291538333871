import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { ForgetPassword } from "./SettingSlice";

export default function FirstPost() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const submitLogin = () => {
    dispatch(ForgetPassword({ email: formik.values.email })).then((res) => {
      if (res?.payload?.code === 1) {
        navigate("/otp", { replace: true });
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email("Kindly enter a valid email.")
      .required("Email is required."),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });
  return (
    <section className="">
      <div className="container-fluid pl-0 ml-0">
        <div className="row align-items-center">
          <div className="col-sm-6 col-md-6 col-lg-6 text-center ml-0 pl-0 sm-mp-lr">
            <div className="logo-bg">
              <div className="">
                <img
                  src="assets/images/image.png"
                  className="img-fluid w-100"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <form className="row login-form" onSubmit={formik.handleSubmit}>
              <div className="form-group col-sm-12 col-md-12 text-center mb-1">
                <div className="text-center">
                  <img
                    alt=""
                    src="assets/images/forgot-password.png"
                    className="forgot-icon modl-icn"
                  />
                </div>
                <h2 className="mdl-ttl">Forgot Password</h2>
                <p className="mdl-sbttl">
                  Please enter your registered email address in order <br />
                  to retrieve your password
                </p>
              </div>
              <div className="col-sm-12">
                {error !== "" && (
                  <div className="alert alert-danger ">{error}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 mb-4 mt-2">
                <div className="input-container">
                  <input
                    type="email"
                    className="form-control pl-5"
                    placeholder="Your Email Address"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("email", e.target.value.trim());
                    }}
                    {...formik.getFieldProps("email")}
                  />
                  <img
                    alt=""
                    src="assets/images/Email.png"
                    className="input-img"
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div className="alert alert-danger mt-2">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>

              <div className="form-group col-sm-12 col-md-12 text-center">
                <button className="btn btn-submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
