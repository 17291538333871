import React, { useState, useEffect, useCallback } from "react";
import Pagination from "react-js-pagination";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DeleteModal from "../../Modal/DeleteModal";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser, GetUserList, ToggleUser } from "./UserSlice";

function ManageUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const totalUser = useSelector((state) => state.user.totalUser);
  const totals = useSelector((state) => state.user.totals);
  const activeUser = useSelector((state) => state.user.activeUser);
  const inActiveUser = useSelector((state) => state.user.inActiveUser);
  const data = useSelector((state) => state.user.list);
  const [activePage, setActivePage] = useState(1);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");
  const [delId, setDelId] = useState("");

  const Paginate = (val) => {
    setActivePage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const confirmDelete = () => {
    dispatch(DeleteUser({ id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        handleShow();
        getList();
        setError(res.payload.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  const getList = useCallback(() => {
    dispatch(
      GetUserList({
        page: activePage,
        search: searchValue,
        status: status,
        limit: 10,
      })
    ).then((res) => {
      if (res?.payload?.code === 0) {
        setError(res.payload.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  }, [dispatch, activePage, searchValue, status]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }
    return () => {
      isRendered = false;
    };
  }, [getList]);

  const handleDelete = (data) => {
    setDelId(data);
    handleShow();
  };

  const handleToggle = (data) => {
    dispatch(ToggleUser(data)).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res.payload.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="user" />

          <article className="col-lg-9">
            <div className="article-body">
              <div className="row align-items-center col-rever">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <ul className="nav nav-tabs text-center group-tabs">
                    <li className="nav-item ">
                      <a
                        className="nav-link revenue-total active"
                        data-toggle="tab"
                        href="#total_student"
                        onClick={() => {
                          setStatus("");
                        }}
                      >
                        <p>
                          <img
                            alt=""
                            src="assets/images/Total-Customers.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{totalUser}</h5>
                        <p>Total Users</p>
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a
                        className="nav-link revenue-total"
                        data-toggle="tab"
                        href="#active_student"
                        onClick={() => {
                          setStatus(1);
                        }}
                      >
                        <p>
                          <img
                            alt=""
                            src="assets/images/Total-Customers.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{activeUser}</h5>
                        <p>Active Users</p>
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a
                        className="nav-link revenue-total"
                        data-toggle="tab"
                        href="#inactive_student"
                        onClick={() => {
                          setStatus(0);
                        }}
                      >
                        <p>
                          <img
                            alt=""
                            src="assets/images/Total-Customers.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{inActiveUser}</h5>
                        <p>Inactive Users</p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <form className="has-search">
                      <span className="form-search-icon form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search user by name"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                      />
                    </form>
                    <Link to="/add-user" className="add-new">
                      + Add New
                    </Link>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12">
                  {error !== "" && (
                    <div className="alert alert-danger mt-2">{error}</div>
                  )}
                </div>
                <div className="col-sm-12">
                  <div className="tab-content">
                    <div id="total_student" className="tab-pane active">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">photo</th>
                              <th scope="col">first name</th>
                              <th scope="col">last name</th>
                              <th scope="col">email address</th>
                              <th scope="col">mobile no.</th>
                              <th scope="col">total booking</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <img
                                        alt=""
                                        src={
                                          item?.image?.includes("http")
                                            ? item.image
                                            : "assets/images/default-user.png"
                                        }
                                        className="table-img"
                                      />
                                    </td>
                                    <td>{item?.first_name || "NA"}</td>
                                    <td>{item?.last_name || "NA"}</td>
                                    <td className="text-lowercase">
                                      {item?.email || "NA"}
                                    </td>
                                    <td>
                                      {item.mobile
                                        ? item.countryCode + item.mobile
                                        : "NA"}
                                    </td>
                                    <td>{item?.total_booking}</td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn" title="View">
                                          <Link to={`/view-user/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn" title="Edit">
                                          <Link to={`/edit-user/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/edit.png"
                                            />
                                          </Link>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Delete"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Activate/Deactivate"
                                        >
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={item.isActive}
                                            onChange={() => {
                                              handleToggle({
                                                status: !item.isActive,
                                                userId: item.id,
                                              });
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={7}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? totals
                                : Number(totalUser)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                    <div id="active_student" className="tab-pane fade">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">photo</th>
                              <th scope="col">first name</th>
                              <th scope="col">last name</th>
                              <th scope="col">email address</th>
                              <th scope="col">mobile no.</th>
                              <th scope="col">total booking</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <img
                                        alt=""
                                        src={
                                          item.image &&
                                          item.image !== "url" &&
                                          item.image !== "null"
                                            ? item.image
                                            : "assets/images/default-user.png"
                                        }
                                        className="table-img"
                                      />
                                    </td>
                                    <td>{item?.first_name || "NA"}</td>
                                    <td>{item?.last_name || "NA"}</td>
                                    <td className="text-lowercase">
                                      {item?.email || "NA"}
                                    </td>
                                    <td>
                                      {item.mobile
                                        ? item.countryCode + item.mobile
                                        : "NA"}
                                    </td>
                                    <td>{item?.total_booking}</td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn" title="View">
                                          <Link to={`/view-user/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn" title="Edit">
                                          <Link to={`/edit-user/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/edit.png"
                                            />
                                          </Link>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Delete"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Activate/Deactivate"
                                        >
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={item.isActive}
                                            onChange={() => {
                                              handleToggle({
                                                status: !item.isActive,
                                                userId: item.id,
                                              });
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={7}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? totals
                                : Number(activeUser)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                    <div id="inactive_student" className="tab-pane fade">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">photo</th>
                              <th scope="col">first name</th>
                              <th scope="col">last name</th>
                              <th scope="col">email address</th>
                              <th scope="col">mobile no.</th>
                              <th scope="col">total booking</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <img
                                        alt=""
                                        src={
                                          item.image &&
                                          item.image !== "url" &&
                                          item.image !== "null"
                                            ? item.image
                                            : "assets/images/default-user.png"
                                        }
                                        className="table-img"
                                      />
                                    </td>
                                    <td>{item?.first_name || "NA"}</td>
                                    <td>{item?.last_name || "NA"}</td>
                                    <td className="text-lowercase">
                                      {item?.email || "NA"}
                                    </td>
                                    <td>
                                      {item.mobile
                                        ? item.countryCode + item.mobile
                                        : "NA"}
                                    </td>
                                    <td>{item?.total_booking}</td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn" title="View">
                                          <Link to={`/view-user/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn" title="Edit">
                                          <Link to={`/edit-user/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/edit.png"
                                            />
                                          </Link>
                                        </button>
                                        <button
                                          type="button"
                                          className="btn"
                                          title="Delete"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Activate/Deactivate"
                                        >
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={item.isActive}
                                            onChange={() => {
                                              handleToggle({
                                                status: !item.isActive,
                                                userId: item.id,
                                              });
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={7}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? totals
                                : Number(inActiveUser)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {delId && (
        <DeleteModal
          show={show}
          setShow={handleShow}
          page="User"
          confirmDelete={confirmDelete}
        />
      )}
    </div>
  );
}

export default ManageUser;
