import { Link } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import Sidebar from "../Sidebar";
import DeleteModal from "../../Modal/DeleteModal";
import Pagination from "react-js-pagination";
import { useDispatch } from "react-redux";
import {
  DeleteNotification,
  GetNotificationList,
  ToggleNotification,
} from "./NotificationSlice";
import { useSelector } from "react-redux";
import moment from "moment";

function ManageNotification() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const total = useSelector((state) => state?.notification?.total);
  const [searchValue, setSearchValue] = useState("");
  const data = useSelector((state) => state?.notification?.list);
  const [activePage, setActivePage] = useState(1);
  const [show, setShow] = useState(false);
  const [delId, setDelId] = useState("");

  const Paginate = (val) => {
    setActivePage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handleDelete = (id) => {
    handleShow();
    setDelId(id);
  };

  const confirmDelete = () => {
    dispatch(DeleteNotification({ id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        getList();
        handleShow();
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const handleNotification = (data) => {
    dispatch(ToggleNotification(data)).then((res) => {
      console.log(res);
      if (res?.payload?.code === 1) {
        getList();
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const getList = useCallback(() => {
    dispatch(GetNotificationList({ page: activePage, search: searchValue }));
  }, [dispatch, activePage, searchValue]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }
    return () => {
      isRendered = false;
    };
  }, [getList]);

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="notification" />

          <article className="col-lg-9">
            <div className="article-body">
              <div className="row align-items-center col-rever">
                <div className="col-sm-4 col-md-6 col-lg-6">
                  <ul className="nav nav-tabs text-center group-tabs">
                    <li className="nav-item Subscription-box">
                      <a
                        className="nav-link revenue-total"
                        data-toggle="tab"
                        href="#total_student"
                      >
                        <p>
                          <img
                            alt=""
                            src="assets/images/Notifications-selected.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{total && total}</h5>
                        <p>Total Notifications</p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-6">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <form className="has-search">
                      <span className="form-search-icon form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Notification by Subject"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                      />
                    </form>
                    <Link to="/add-notification" className="add-new">
                      <img alt="" src="assets/images/Write-New.png" /> Write New
                    </Link>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12">
                  {error !== "" && (
                    <div className="alert alert-success mt-2">{error}</div>
                  )}
                </div>
                <div className="col-sm-12">
                  <div className="tab-content">
                    <div id="total_student" className="tab-pane active">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">date</th>
                              <th scope="col">subject</th>
                              <th scope="col">message</th>
                              <th scope="col" className="width100">
                                sent to
                              </th>
                              <th scope="col">time</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>
                                      {moment(item.createdAt).format(
                                        "DD MMM YYYY"
                                      )}
                                    </td>
                                    <td>{item.subject}</td>
                                    <td>{item.message}</td>
                                    <td className="width100">
                                      <div className="line-clamp-1">
                                        {item.customer_list
                                          ?.map((item) => {
                                            return (
                                              item?.userDetails?.first_name +
                                              " " +
                                              item?.userDetails?.last_name
                                            );
                                          })
                                          .join(", ") || "NA"}{" "}
                                      </div>
                                    </td>
                                    <td>
                                      {moment(item.createdAt).format("hh:mm a")}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn">
                                          <Link
                                            to={`/view-notification/${item.id}`}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn">
                                          <span
                                            onClick={() => {
                                              handleDelete(item.id);
                                            }}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                        <button className="btn">
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={item.isActive}
                                            onChange={() => {
                                              handleNotification({
                                                notificationId: item.id,
                                                status:
                                                  item.isActive === true
                                                    ? 0
                                                    : 1,
                                              });
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Number(total)}
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {show && (
        <DeleteModal
          show={show}
          setShow={handleShow}
          page="Notification"
          confirmDelete={confirmDelete}
        />
      )}
    </div>
  );
}

export default ManageNotification;
