import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { GetUserDetail } from "./UserSlice";
import moment from "moment";

function ViewUser() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const data = useSelector((state) => state?.user?.userDetail);
  const bookings = useSelector((state) => state?.user?.bookingList);
  const totalBooking = useSelector((state) => state?.user?.totalBooking);

  const getDetails = useCallback(() => {
    dispatch(GetUserDetail({ id: id }));
  }, [id, dispatch]);

  console.log(data, "Data");
  useEffect(() => {
    getDetails();
  }, [getDetails]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="user" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy text-center">
              <h2>User Details</h2>
              <div className="student-details-box shadow-box">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tr>
                      <td>
                        <div className="student-img">
                          <img
                            alt=""
                            src={
                              data?.image?.includes("http")
                                ? data?.image
                                : "assets/images/default-user.png"
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <p>
                          <img alt="" src="assets/images/First-Name.png" />
                        </p>
                        <p>
                          <b>First Name</b>
                        </p>
                        <p>{data?.first_name || "NA"}</p>
                      </td>
                      <td>
                        <p>
                          <img alt="" src="assets/images/First-Name.png" />
                        </p>
                        <p>
                          <b>Last Name</b>
                        </p>
                        <p>{data?.last_name || "NA"}</p>
                      </td>
                      <td>
                        <p>
                          <img alt="" src="assets/images/mobile.png" />
                        </p>
                        <p>
                          <b>mobile no.</b>
                        </p>
                        <p>
                          {data?.mobile
                            ? data?.countryCode + data?.mobile
                            : "NA"}
                        </p>
                      </td>
                      <td>
                        <p>
                          <img alt="" src="assets/images/add-email.png" />
                        </p>
                        <p>
                          <b>email address</b>
                        </p>
                        <p className="text-lowercase">{data?.email || "NA"}</p>
                      </td>
                      <td>
                        <p>
                          <img
                            alt=""
                            src="assets/images/add-total-bookings.png"
                          />
                        </p>
                        <p>
                          <b>total booking</b>
                        </p>
                        <p>{totalBooking}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <h2 className="my-5">Total Booking Details</h2>

              <div className="table-responsive text-center shadow-box">
                <table className="table storetable">
                  <thead className="text-center">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">vendor name</th>
                      <th scope="col">service</th>
                      <th scope="col">scheduled date</th>
                      <th scope="col">address</th>
                      <th scope="col">payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookings?.length > 0 ? (
                      bookings?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {moment(item.createdAt).format("DD MMM YYYY") ||
                                "NA"}
                            </td>
                            <td>{item?.vendor_details?.fullName || "NA"}</td>
                            <td>
                              {item.service_list?.length > 0
                                ? item?.service_list
                                    ?.map((data, i) => {
                                      return data.name;
                                    })
                                    .join(", ")
                                : "NA"}
                            </td>
                            <td>
                              {moment(item?.date).format("DD MMM YYYY")}{" "}
                              {item?.time_slot}
                            </td>
                            <td>
                              {item?.bookingAddress
                                ? item?.bookingAddress?.flat +
                                  ", " +
                                  item?.bookingAddress?.society +
                                  ", " +
                                  item?.bookingAddress?.city +
                                  ", " +
                                  `near ${item?.bookingAddress?.landmark}`
                                : "NA"}
                            </td>
                            <td>
                              {item?.total_amount && (
                                <i className="fa fa-inr" aria-hidden="true"></i>
                              )}{" "}
                              {item.total_amount || "NA"}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6}>No data found.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default ViewUser;
