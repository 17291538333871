import { configureStore } from "@reduxjs/toolkit";
import BookingSlice from "../features/Booking/BookingSlice";
import DashbaordSlice from "../features/dashboard/DashboardSlice";
import NotificationSlice from "../features/Notification/NotificationSlice";
import ServicesSlice from "../features/Services/ServicesSlice";
import SettingSlice from "../features/setting/SettingSlice";
import PlanSlice from "../features/Subscription/PlanSlice";
import UserSlice from "../features/User/UserSlice";
import VenderSlice from "../features/Vender/VenderSlice";
// import counterReducer from '../features/counter/counterSlice';

export const store = configureStore({
  reducer: {
    // counter: counterReducer,
    login: SettingSlice,
    user: UserSlice,
    services: ServicesSlice,
    vender: VenderSlice,
    plan: PlanSlice,
    booking: BookingSlice,
    notification: NotificationSlice,
    dashboard: DashbaordSlice,
  },
});
