import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Sidebar from "../Sidebar";
import { GetEarnings, GetYearList } from "./DashboardSlice";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import moment from "moment";
import { Dropdown } from "react-bootstrap";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

function Dashbaord() {
  const dispatch = useDispatch();
  const [currentYear, setCurrentYear] = useState("");
  const data = useSelector((state) => state?.dashboard?.data);
  const total_booking = useSelector((state) => state?.dashboard?.total_booking);
  const total_cancelled_booking = useSelector(
    (state) => state?.dashboard?.total_cancelled_booking
  );
  const total_completed_booking = useSelector(
    (state) => state?.dashboard?.total_completed_booking
  );
  const total_customers = useSelector(
    (state) => state?.dashboard?.total_customers
  );
  const [years, setYears] = useState([]);
  const [barData, setBarData] = useState([]);
  const [yearForGraph, setYearForGraph] = useState(
    moment().format("YYYY") || ""
  );

  // const years = useSelector((state) => state?.dashboard?.year);

  const BarData = {
    labels: barData?.map((item) => item.month),
    datasets: [
      {
        data: barData?.map((item) => item.earnings),
        backgroundColor: "#404040",
        barThickness: 18,
        lineTension: 0,
      },
    ],
  };

  const getRevenue = useCallback(() => {
    dispatch(GetEarnings({ year: yearForGraph })).then((res) => {
      if (res?.payload?.code === 1) {
        let object = res?.payload?.data?.monthly_data;
        let values = Object.values(object);
        setBarData(values);
      }
    });
  }, [dispatch, yearForGraph]);

  const getYear = useCallback(() => {
    dispatch(GetYearList()).then((res) => {
      if (res?.payload?.code === 1) {
        setYears(res?.payload?.data?.year_list);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getRevenue();
      getYear();
    }
    return () => {
      isRendered = false;
    };
  }, [getRevenue, getYear]);

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="dashboard" />
          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                      <div className="revenue-total">
                        <img
                          alt=""
                          src="assets/images/Total-Customers.png"
                          className="img-fluid"
                        />
                        <h5>{total_customers && total_customers}</h5>
                        <p>Total Customers</p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                      <div className="revenue-total">
                        <img
                          alt=""
                          src="assets/images/Total-Bookings.png"
                          className="img-fluid"
                        />
                        <h5>{total_booking && total_booking}</h5>
                        <p>Total Bookings</p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                      <div className="revenue-total">
                        <img
                          alt=""
                          src="assets/images/Completed-Bookings.png"
                          className="img-fluid"
                        />
                        <h5>
                          {total_completed_booking && total_completed_booking}
                        </h5>
                        <p>Completed Bookings</p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
                      <div className="revenue-total">
                        <img
                          alt=""
                          src="assets/images/Canceled-Bookings.png"
                          className="img-fluid"
                        />
                        <h5>{total_cancelled_booking}</h5>
                        <p>Cancelled Bookings</p>
                      </div>
                    </div>
                  </div>

                  <div className="income-chart">
                    <div className="row align-items-center">
                      <div className="col-sm-6 col-md-6 mt-4">
                        <h4>Total revenue</h4>
                      </div>
                      <div className="col-sm-6 col-md-6 mt-4">
                        <div className="year-drop">
                          <span className="yeet">Year</span>
                          {/* <div className="dropdown">
                            <a
                              href={() => {
                                return false;
                              }}
                              className=""
                              id="chart-fltr"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <p className="cursor-pointer">
                                {currentYear && currentYear}
                                <span className="arrow-down"></span>
                              </p>
                            </a>
                            <div
                              className="dropdown-menu dropdown-chart-fltr"
                              role="menu"
                              aria-labelledby="chart-fltr"
                            >
                              {years?.map((item, index) => {
                                return (
                                  <p
                                    className="mb-2 cursor-pointer"
                                    onClick={() => {
                                      setCurrentYear(item?.year);
                                      setYearForGraph(item?.year);
                                    }}
                                  >
                                    {item?.year}
                                  </p>
                                );
                              })}
                            </div>
                          </div> */}

                          <select
                            className="selectTag"
                            onChange={(e) => {
                              let val = e.target.value;
                              setYearForGraph(val);
                            }}
                          >
                            {years?.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  value={item.year}
                                  className="m-2 cursor-pointer dropdown-chart-fltr"
                                >
                                  {item?.year}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <div className="chart-bg">
                          <Bar
                            data={BarData}
                            options={{
                              plugins: {
                                legend: {
                                  display: false,
                                },
                                tooltip: {
                                  backgroundColor: "#fff",
                                  bodyColor: "#000",
                                  titleMarginBottom: 10,
                                  titleColor: "#000",
                                  titleFontSize: 15,
                                  borderColor: "#dddfed",
                                  borderWidth: 1,
                                  xPadding: 15,
                                  yPadding: 15,
                                  displayColors: false,
                                  intersect: false,
                                  mode: "index",
                                  caretPadding: 10,
                                },
                              },
                              maintainAspectRatio: true,
                              responsive: true,
                              layout: {
                                padding: {
                                  left: 10,
                                  right: 25,
                                  top: 25,
                                  bottom: 0,
                                },
                              },

                              scales: {
                                x: {
                                  time: {
                                    unit: "date",
                                  },
                                  grid: {
                                    display: false,
                                    drawBorder: false,
                                  },
                                  ticks: {
                                    maxTicksLimit: 12,
                                  },
                                },
                                y: {
                                  barPercentage: 0.1,
                                  ticks: {
                                    maxTicksLimit: 10,
                                    padding: 10,
                                  },
                                  grid: {
                                    color: "#dddddd",
                                    zeroLineColor: "rgb(234, 236, 244)",
                                    drawBorder: true,
                                    borderDash: [5],
                                    zeroLineBorderDash: [5],
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default Dashbaord;
