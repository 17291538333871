import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../Modal/DeleteModal";
import Sidebar from "../Sidebar";
import { GetBookingList } from "./BookingSlice";

function ManageBooking() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("asdfdasf");

  const completed = useSelector((state) => state?.booking?.count?.completed);
  const newBooking = useSelector((state) => state?.booking?.count?.new);
  const cancelled = useSelector((state) => state?.booking?.count?.cancelled);
  const upcoming = useSelector((state) => state?.booking?.count?.upcoming);
  const total = useSelector((state) => state?.booking?.count?.total);
  const totals = useSelector((state) => state?.booking?.count?.totals);
  const [searchValue, setSearchValue] = useState("");
  const data = useSelector((state) => state?.booking?.list);
  const [activePage, setActivePage] = useState(1);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(0);

  const Paginate = (val) => {
    setActivePage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const confirmDelete = () => {
    console.log("Conffirm delete");
    handleShow();
  };

  const getList = useCallback(() => {
    dispatch(
      GetBookingList({ page: activePage, status: status, search: searchValue })
    );
  }, [dispatch, activePage, status, searchValue]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }
    return () => {
      isRendered = false;
    };
  }, [getList]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="booking" />

          <article className="col-lg-9">
            <div className="article-body">
              <div className="row align-items-center col-rever">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <ul className="nav nav-tabs text-center group-tabs">
                    <li className="nav-item ">
                      <a className="nav-link revenue-total">
                        <p>
                          <img
                            alt=""
                            src="assets/images/Total-Bookings.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{total}</h5>
                        <p>Total Booking</p>
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a className="nav-link revenue-total">
                        <p>
                          <img
                            alt=""
                            src="assets/images/Total-Bookings.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{completed}</h5>
                        <p>Completed Booking</p>
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a className="nav-link revenue-total mr-0">
                        <p>
                          <img
                            alt=""
                            src="assets/images/Total-Bookings.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{cancelled}</h5>
                        <p>Cancelled Booking</p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <form className="has-search w-100">
                      <span className="form-search-icon form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Booking by ID"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <ul className="nav nav-tabs text-center group-tabs vendor-tabs booking-tab">
                    <li className="nav-item ">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#new"
                        onClick={() => {
                          setStatus(0);
                        }}
                      >
                        New
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Upcoming"
                        onClick={() => {
                          setStatus(6);
                        }}
                      >
                        Upcoming
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Completed"
                        onClick={() => {
                          setStatus(4);
                        }}
                      >
                        Completed
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a
                        className="nav-link mr-0"
                        data-toggle="tab"
                        href="#Cancelled"
                        onClick={() => {
                          setStatus(2);
                        }}
                      >
                        Cancelled
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12">
                  <div className="tab-content">
                    <div id="new" className="tab-pane active">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">booking ID</th>
                              <th scope="col">user name</th>
                              <th scope="col">services</th>
                              <th scope="col">date & time</th>
                              <th scope="col">address</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data?.length > 0 ? (
                              data?.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{item.id}</td>
                                    <td>
                                      {item?.user_details
                                        ? item?.user_details?.first_name +
                                          " " +
                                          item?.user_details?.last_name
                                        : "NA"}
                                    </td>
                                    <td>
                                      {item?.service_list?.length > 0
                                        ? item.service_list
                                            .map((service) => service.name)
                                            .join(", ")
                                        : "NA"}
                                    </td>
                                    <td>{item.date + " " + item.time_slot}</td>
                                    <td>
                                      {item.bookingAddress
                                        ? item.bookingAddress.flat +
                                          ", " +
                                          item.bookingAddress.society +
                                          ", " +
                                          item.bookingAddress.city
                                        : "NA"}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn" title="View">
                                          <Link to={`/booking/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn" title="Delete">
                                          <span
                                            onClick={() => {
                                              setShow(!show);
                                            }}
                                          >
                                            {/* <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            /> */}
                                          </span>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? Number(totals)
                                : Number(newBooking)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                    <div id="Upcoming" className="tab-pane fade">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">booking ID</th>
                              <th scope="col">user name</th>
                              <th scope="col">services</th>
                              <th scope="col">date & time</th>
                              <th scope="col">address</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{item.id}</td>
                                    <td>
                                      {item?.user_details
                                        ? item?.user_details?.first_name +
                                          " " +
                                          item?.user_details?.last_name
                                        : "NA"}
                                    </td>
                                    <td>
                                      {item?.service_list?.length > 0
                                        ? item.service_list
                                            .map((service) => service.name)
                                            .join(", ")
                                        : "NA"}
                                    </td>
                                    <td>{item.date + " " + item.time_slot}</td>
                                    <td>
                                      {item.bookingAddress
                                        ? item.bookingAddress.flat +
                                          ", " +
                                          item.bookingAddress.society +
                                          ", " +
                                          item.bookingAddress.city
                                        : "NA"}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn">
                                          <Link to={`/booking/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn">
                                          <span
                                            onClick={() => {
                                              setShow(!show);
                                            }}
                                          >
                                            {/* <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            /> */}
                                          </span>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? Number(totals)
                                : Number(upcoming)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                    <div id="Completed" className="tab-pane fade">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">booking ID</th>
                              <th scope="col">user name</th>
                              <th scope="col">services</th>
                              <th scope="col">date & time</th>
                              <th scope="col">address</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{item.id}</td>
                                    <td>
                                      {item?.user_details
                                        ? item?.user_details?.first_name +
                                          " " +
                                          item?.user_details?.last_name
                                        : "NA"}
                                    </td>
                                    <td>
                                      {item?.service_list?.length > 0
                                        ? item.service_list
                                            .map((service) => service.name)
                                            .join(", ")
                                        : "NA"}
                                    </td>
                                    <td>{item.date + " " + item.time_slot}</td>
                                    <td>
                                      {item.bookingAddress
                                        ? item.bookingAddress.flat +
                                          ", " +
                                          item.bookingAddress.society +
                                          ", " +
                                          item.bookingAddress.city
                                        : "NA"}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn">
                                          <Link to={`/booking/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn">
                                          <span
                                            onClick={() => {
                                              setShow(!show);
                                            }}
                                          >
                                            {/* <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            /> */}
                                          </span>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? Number(totals)
                                : Number(completed)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                    <div id="Cancelled" className="tab-pane fade">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">booking ID</th>
                              <th scope="col">user name</th>
                              <th scope="col">services</th>
                              <th scope="col">date & time</th>
                              <th scope="col">address</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{item.id}</td>
                                    <td>
                                      {item?.user_details
                                        ? item?.user_details?.first_name +
                                          " " +
                                          item?.user_details?.last_name
                                        : "NA"}
                                    </td>
                                    <td>
                                      {item?.service_list?.length > 0
                                        ? item.service_list
                                            .map((service) => service.name)
                                            .join(", ")
                                        : "NA"}
                                    </td>
                                    <td>{item.date + " " + item.time_slot}</td>
                                    <td>
                                      {item.bookingAddress
                                        ? item.bookingAddress.flat +
                                          ", " +
                                          item.bookingAddress.society +
                                          ", " +
                                          item.bookingAddress.city
                                        : "NA"}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn">
                                          <Link
                                            to={`/cancelled-booking/${item.id}`}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn">
                                          <span
                                            onClick={() => {
                                              setShow(!show);
                                            }}
                                          >
                                            {/* <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            /> */}
                                          </span>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? Number(totals)
                                : Number(cancelled)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {show && (
        <DeleteModal
          show={show}
          setShow={handleShow}
          confirmDelete={confirmDelete}
        />
      )}
    </div>
  );
}

export default ManageBooking;
