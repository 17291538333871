import React from "react";
import Routers, { RenderRoutes } from "./routes/routes";
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./app/store";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <RenderRoutes routers={Routers} />
      </Router>
    </Provider>
  );
}

export default App;
