import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Constant";

const initialState = {
  list: [],
  isLoading: true,
  total: 0,
  active: 0,
  inActive: 0,
  approved: 0,
  rejected: 0,
  vendorId: "",
  timeSlot: [],
  weekDays: [],
  vendorDetail: {},
  totals: 1,
};

export const GetVenderList = createAsyncThunk("vender list", async (obj) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`${BASE_URL}vendor/vendorList`, {
    params: {
      page: obj.page,
      search: obj.search,
      status: obj.status,
      limit: 10,
    },
    headers: {
      token,
    },
  });

  return response.data;
});

export const GetVenderDetail = createAsyncThunk(
  "vender detail",
  async (obj) => {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${BASE_URL}vendor/getVendorDetails`, {
      params: {
        vendorId: obj.id,
      },
      headers: {
        token,
      },
    });

    return response.data;
  }
);

export const AddVendor = createAsyncThunk("vender add", async (obj) => {
  const token = localStorage.getItem("token");

  let formData = new FormData();

  for (var key in obj) {
    formData.append(key, obj[key]);
  }

  const response = await axios.post(`${BASE_URL}vendor/addVendor`, formData, {
    headers: {
      token,
    },
  });
  return response.data;
});

export const AddVendorDocuments = createAsyncThunk(
  "vender docs",
  async (obj) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (var key in obj) {
      formData.append(key, obj[key]);
    }

    const response = await axios.post(
      `${BASE_URL}vendor/addVendorDocuments`,
      formData,
      {
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const AddVendorAccount = createAsyncThunk(
  "addVendorAccountDetails",
  async (obj) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (var key in obj) {
      formData.append(key, obj[key]);
    }

    const response = await axios.post(
      `${BASE_URL}vendor/addVendorAccountDetails`,
      formData,
      {
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const AddVendorAvailabilityCall = createAsyncThunk(
  "AddVendorAvailability",
  async (obj) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (var key in obj) {
      if (typeof obj[key] === "object") {
        formData.append(key, JSON.stringify(obj[key]));
      } else {
        formData.append(key, obj[key]);
      }
    }

    const response = await axios.post(
      `${BASE_URL}vendor/addVendorAvailability`,
      formData,
      {
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const AddVendorServices = createAsyncThunk(
  "addVendorAccountDetails",
  async (obj) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(
      `${BASE_URL}vendor/addVendorService`,
      obj,
      {
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const DeleteVendorServices = createAsyncThunk(
  "DeleteVendorServices",
  async (obj) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (var key in obj) {
      formData.append(key, obj[key]);
    }
    const response = await axios.delete(
      `${BASE_URL}vendor/removeVendorService`,
      formData,
      {
        headers: {
          token: token,
        },
      }
    );
    return response.data;
  }
);

export const DeleteVendor = createAsyncThunk("DeleteVendor", async (obj) => {
  const token = localStorage.getItem("token");

  const response = await axios.delete(`${BASE_URL}vendor/removeVendor`, {
    params: {
      vendorId: obj.id,
    },
    headers: {
      token: token,
    },
  });
  return response.data;
});

export const UpdateVendorStatus = createAsyncThunk(
  "UpdateVendorStatus",
  async (obj) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (var key in obj) {
      formData.append(key, obj[key]);
    }
    const response = await axios.patch(
      `${BASE_URL}vendor/updateVendorStatus`,
      formData,
      {
        headers: {
          token: token,
        },
      }
    );
    return response.data;
  }
);

export const ActivateDeactivateVendor = createAsyncThunk(
  "activateDeactivateVendor",
  async (obj) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (var key in obj) {
      formData.append(key, obj[key]);
    }
    const response = await axios.patch(
      `${BASE_URL}vendor/activateDeactivateVendor`,
      formData,
      {
        headers: {
          token: token,
        },
      }
    );
    return response.data;
  }
);

export const GetTimeSlot = createAsyncThunk(
  "GetTimeSlot",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${BASE_URL}vendor/getTimeSlot`, {
      headers: {
        token,
      },
    });
    return response.data;
  }
);

export const GetWeekDays = createAsyncThunk(
  "GetWeekDays",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${BASE_URL}vendor/getWeekDays`, {
      headers: {
        token,
      },
    });
    return response.data;
  }
);

export const ServiceListForVendor = createAsyncThunk(
  "ServiceListForVendor",
  async (object) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (const key in object) {
      if (typeof object[key] === "object") {
        formData.append(key, JSON.stringify(object[key]));
      }
    }

    const response = await axios.post(
      `${BASE_URL}service/getSubServiceListForVendor`,
      formData,
      {
        headers: {
          token,
        },
      }
    );

    return response.data;
  }
);

export const VenderSlice = createSlice({
  name: "name",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [GetVenderList.fulfilled]: (state, action) => {
      state.list = action?.payload?.data?.vendor;
      state.total = action?.payload?.data?.total_vendor;
      state.totals =
        action?.payload?.data?.total_vendor_with_filter !== 0
          ? action?.payload?.data?.total_vendor_with_filter
          : 1;
      state.approved = action?.payload?.data?.total_accepted_vendor;
      state.rejected = action?.payload?.data?.total_rejected_vendor;
      state.active = action?.payload?.data?.total_active_vendor;
      state.inActive = action?.payload?.data?.total_deactive_vendor;
    },
    [AddVendor.fulfilled]: (state, action) => {
      state.newVendorId = action?.payload?.data?.vendor?.id;
    },
    [GetTimeSlot.fulfilled]: (state, action) => {
      state.timeSlot = action?.payload?.data?.times;
    },
    [GetWeekDays.fulfilled]: (state, action) => {
      state.weekDays = action?.payload?.data?.days;
    },
    [GetVenderDetail.fulfilled]: (state, action) => {
      state.vendorDetail = action?.payload?.data;
    },
  },
});

export default VenderSlice.reducer;
