import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import Sidebar from "../Sidebar";
import { useDispatch } from "react-redux";
import { ChangePasswordCall } from "./SettingSlice";

function ChangePassword() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const submitLogin = () => {
    dispatch(
      ChangePasswordCall({
        current_password: formik.values.currentPassword,
        new_password: formik.values.new_password,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res.payload.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError(res.payload.message);

        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  const validationSchema = yup.object().shape({
    currentPassword: yup.string().required("Required."),

    newPassword: yup.string().required("Required."),
    passwordConfirmation: yup
      .string()
      .required("Kindly confirm you new password.")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="change-password" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <form
                className="row login-form mt-4"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group col-sm-12 col-md-12 text-center mb-1">
                  <div className="text-center">
                    <img
                      alt=""
                      src="assets/images/Reset-Password.png"
                      className="forgot-icon modl-icn"
                    />
                  </div>
                  <h2 className="mdl-ttl">Change Password</h2>
                  <p className="mdl-sbttl">Please change your password here.</p>
                </div>
                <div className="col-sm-12"></div>
                <div className="form-group col-sm-12 col-md-12 mb-4">
                  <div className="input-container">
                    <input
                      type="password"
                      className="form-control pl-5"
                      placeholder="Current Password"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("currentPassword")}
                    />
                    <img
                      alt=""
                      src="assets/images/Password.png"
                      className="input-img pass"
                    />
                  </div>
                  {formik.touched.currentPassword &&
                  formik.errors.currentPassword ? (
                    <div className="alert alert-danger mt-2">
                      {formik.errors.currentPassword}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <Link to="/forgot-password" className="forgot-pass">
                    Forgot Password?
                  </Link>
                </div>
                <div className="form-group col-sm-12 col-md-12 mb-4">
                  <div className="input-container">
                    <input
                      type="password"
                      className="form-control pl-5"
                      placeholder="New Password"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("newPassword")}
                    />
                    <img
                      alt=""
                      src="assets/images/Password.png"
                      className="input-img pass"
                    />
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="alert alert-danger mt-2">
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 mb-4">
                  <div className="input-container">
                    <input
                      type="password"
                      className="form-control pl-5"
                      placeholder="Confirm New Password"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("passwordConfirmation")}
                    />
                    <img
                      alt=""
                      src="assets/images/Password.png"
                      className="input-img pass"
                    />
                  </div>
                  {formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation ? (
                    <div className="alert alert-danger mt-2">
                      {formik.errors.passwordConfirmation}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-submit">
                    Done
                  </button>
                  {error !== "" && (
                    <div className="alert alert-danger mt-2 ">{error}</div>
                  )}
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
