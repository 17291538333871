import React, { useEffect, useCallback } from "react";
import Sidebar from "../Sidebar";
import { PlanDetail } from "./PlanSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

function ViewSubscription() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector((state) => state.plan.planData);

  const getDetails = useCallback(() => {
    dispatch(PlanDetail({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }
    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="subscription" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Standard Plan Details</h2>
              <div className="student-details-box shadow-box">
                <div className="Booking-Details">
                  <span>
                    <img
                      alt=""
                      src="assets/images/Standard-Plan.png"
                      className="img-fluid usr-img"
                    />
                  </span>
                  <span>
                    <h5 className="font-weight-bold">{data?.name}</h5>
                    <p>
                      <span>
                        <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                        {data?.price}/Monthly
                      </span>
                    </p>
                  </span>
                </div>
                <hr />
                <h5 className="font-weight-bold mt-4">Features</h5>
                <ul className="Features-list">
                  {data?.features?.map((item, i) => {
                    return <li key={i}>{item?.features}</li>;
                  })}
                </ul>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default ViewSubscription;
