import { Modal } from "react-bootstrap";
import React from "react";

function ViewSubServices({ viewShow, setViewShow, data }) {
  const subServicesModal = () => {
    return (
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content b-r-30">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setViewShow();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="text-center mt-5">
              <div className="student-img">
                <img
                  alt=""
                  src={
                    data?.image !== "" &&
                    data?.image !== "url" &&
                    data?.image !== "null" &&
                    data?.image !== null
                      ? data?.image
                      : "assets/images/default-user.png"
                  }
                  className="img-fluid"
                />
              </div>
              <div className="row mt-4 justify-content-center">
                <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                  <h5>Sub-Service</h5>
                  <p className="text-capitalize">{data?.name}</p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                  <h5>Price</h5>
                  <p className="text-capitalize">
                    <img alt="" src="assets/images/currency.png" />{" "}
                    {data?.price}
                  </p>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
                  <h5>Service Name</h5>
                  <p className="text-capitalize">
                    {data?.parentServiceDetails?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal show={viewShow} onHide={setViewShow} dialogAs={subServicesModal} />
  );
}

export default ViewSubServices;
