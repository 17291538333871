import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Sidebar({ page }) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };
  return (
    <div className="col-lg-3 store-sidebar admin-sidebar">
      <div className="shadow-part">
        <Link
          className="navbar-brand bg-none px-3 mx-lg-auto d-lg-block"
          to="/dashboard"
        >
          <img alt="" src="assets/images/logo.png" className="img-fluid" />
        </Link>
        <button
          className="btn btn-blue float-right menu-toggle d-lg-none"
          onClick={() => {
            handleShow();
          }}
        >
          <i className="fa fa-bars"> </i>
        </button>
        <nav
          className={show ? "show collapse d-lg-block" : "collapse d-lg-block"}
        >
          <ul className="list-unstyled mb-0">
            <li>
              <Link
                to="/dashboard"
                className={page === "dashboard" ? "active" : ""}
              >
                <div>
                  <img
                    alt=""
                    src="assets/images/Dashboard.png"
                    className="img-fluid sidebar-icon"
                  />
                  <img
                    alt=""
                    src="assets/images/Dashboard-selected.png"
                    className="img-fluid sidebar-icon sidebar-icon-select"
                  />
                </div>
                <span> dashboard </span>
              </Link>
            </li>
            <li>
              <Link
                to="/manage-user"
                className={page === "user" ? "active" : ""}
              >
                <div>
                  <img
                    alt=""
                    src="assets/images/Users.png"
                    className="img-fluid sidebar-icon"
                  />
                  <img
                    alt=""
                    src="assets/images/Users-selected.png"
                    className="img-fluid sidebar-icon sidebar-icon-select"
                  />
                </div>
                <span> manage users</span>
              </Link>
            </li>

            <li>
              <Link
                to="/manage-vender"
                className={page === "vender" ? "active" : ""}
              >
                <div>
                  <img
                    alt=""
                    src="assets/images/Vendors.png"
                    className="img-fluid sidebar-icon"
                  />
                  <img
                    alt=""
                    src="assets/images/Vendors-selected.png"
                    className="img-fluid sidebar-icon sidebar-icon-select"
                  />
                </div>
                <span> manage vendors</span>
              </Link>
            </li>

            <li
              className={
                page === "services" || page === "sub-services" ? "sb-act" : ""
              }
            >
              <a
                href={() => {
                  return "a";
                }}
                className={
                  page === "services" || page === "sub-services"
                    ? "show-collape-border  active cursor-pointer"
                    : "show-collape-border cursor-pointer"
                }
                data-target="#services"
                data-toggle="collapse"
              >
                <div>
                  <img
                    alt=""
                    src="assets/images/Services.png"
                    className="img-fluid sidebar-icon"
                  />
                  <img
                    alt=""
                    src="assets/images/Services-selected.png"
                    className="img-fluid sidebar-icon sidebar-icon-select"
                  />
                </div>
                <span> manage services </span>
              </a>
              <div
                className={
                  page === "services" || page === "sub-services"
                    ? "collapse collapse-border active show"
                    : "collapse collapse-border"
                }
                id="services"
              >
                <Link
                  className={
                    page === "services"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  to="/manage-services"
                >
                  <span>services</span>
                </Link>
                <Link
                  className={
                    page === "sub-services"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                  to="/manage-sub-services"
                >
                  <span>sub-services</span>
                </Link>
              </div>
            </li>

            <li>
              <Link
                to="/manage-booking"
                className={page === "booking" ? "active" : ""}
              >
                <div>
                  <img
                    alt=""
                    src="assets/images/Bookings.png"
                    className="img-fluid sidebar-icon"
                  />
                  <img
                    alt=""
                    src="assets/images/Bookings-selected.png"
                    className="img-fluid sidebar-icon sidebar-icon-select"
                  />
                </div>
                <span> manage booking </span>
              </Link>
            </li>

            <li>
              <Link
                to="/manage-notification"
                className={page === "notification" ? "active" : ""}
              >
                <div>
                  <img
                    alt=""
                    src="assets/images/Notifications.png"
                    className="img-fluid sidebar-icon"
                  />
                  <img
                    alt=""
                    src="assets/images/Notifications-selected.png"
                    className="img-fluid sidebar-icon sidebar-icon-select"
                  />
                </div>
                <span> manage notifications </span>
              </Link>
            </li>

            <li>
              <Link
                to="/subscription-plan"
                className={page === "subscription" ? "active" : ""}
              >
                <div>
                  <img
                    alt=""
                    src="assets/images/Subscription-Plan.png"
                    className="img-fluid sidebar-icon"
                  />
                  <img
                    alt=""
                    src="assets/images/Subscription-Plan-selected.png"
                    className="img-fluid sidebar-icon sidebar-icon-select"
                  />
                </div>
                <span> subscription plan </span>
              </Link>
            </li>

            <li className={page === "change-password" ? "sb-act" : ""}>
              <a
                href={() => {
                  return "a";
                }}
                className={
                  page === "change-password"
                    ? "show-collape-border cursor-pointer active"
                    : "show-collape-border cursor-pointer"
                }
                data-target="#setting"
                data-toggle="collapse"
              >
                <div>
                  <img
                    alt=""
                    src="assets/images/Settings.png"
                    className="img-fluid sidebar-icon"
                  />
                  <img
                    alt=""
                    src="assets/images/Settings-selected.png"
                    className="img-fluid sidebar-icon sidebar-icon-select"
                  />
                </div>
                <span> setting </span>
              </a>
              <div
                className={
                  page === "change-password"
                    ? "collapse collapse-border active show"
                    : "collapse collapse-border"
                }
                id="setting"
              >
                <Link
                  className={
                    page === "change-password"
                      ? "dropdown-item active"
                      : "dropdown"
                  }
                  to="/change-password"
                >
                  <span>Change Password</span>
                </Link>
                <a
                  className={"dropdown-item cursor-pointer"}
                  href={() => {
                    return "a";
                  }}
                  onClick={() => {
                    navigate("/", { replace: true });
                  }}
                >
                  <span>Log Out</span>
                  <div>
                    <img
                      alt=""
                      src="assets/images/logout-select.png"
                      className="img-fluid sidebar-icon"
                    />
                    <img
                      alt=""
                      src="assets/images/logout.png"
                      className="img-fluid sidebar-icon  sidebar-icon-select"
                    />
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
