import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Constant";

const initialState = {
  token: null,
  isLoading: true,
  email: "",
};

export const LoggingIn = createAsyncThunk("LoggingIn", async (obj) => {
  try {
    const response = await axios.get(`${BASE_URL}admin/login`, {
      params: {
        email: obj.email,
        password: obj.password,
      },
    });
    await localStorage.setItem("token", response?.data?.data?.token);

    return response?.data;
  } catch (error) {
    console.log(error);
  }
});

export const ForgetPassword = createAsyncThunk(
  "forgotPassword",
  async (obj) => {
    const response = await axios.get(`${BASE_URL}admin/forgotPassword`, {
      params: {
        email: obj.email,
      },
    });
    if (response?.data?.code === 1) {
      await localStorage.setItem("email", response?.config?.params?.email);
    }

    return response?.data;
  }
);

export const OTPCall = createAsyncThunk("OtpCallthunk", async (obj) => {
  const email = await localStorage.getItem("email");
  const token = await localStorage.getItem("otptoken");

  const response = await axios.get(`${BASE_URL}admin/verifyOtp`, {
    params: {
      code: obj.code,
      email: email,
    },
  });
  if (response?.data?.code === 1) {
    await localStorage.setItem("otptoken", response?.data?.data?.token);
  }
  return response.data;
});

export const ResendCode = createAsyncThunk(
  "ResendCodethunk",
  async (obj, { getState }) => {
    const email = localStorage.getItem("email");

    const response = await axios.get(`${BASE_URL}admin/resendOtp`, {
      params: {
        email: email,
      },
    });

    return response.data;
  }
);

export const ResetPasswordCall = createAsyncThunk(
  "resetPassword",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("otptoken");

    const response = await axios.put(
      `${BASE_URL}admin/resetPassword`,
      {
        password: obj.password,
      },
      {
        headers: {
          token: token,
        },
      }
    );
    return response?.data;
  }
);

export const ChangePasswordCall = createAsyncThunk(
  "changePassword",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (var key in obj) {
      formData.append(key, obj[key]);
    }
    const response = await axios.put(
      `${BASE_URL}admin/changePassword`,
      formData,
      {
        headers: {
          token: token,
        },
      }
    );
    return response?.data;
  }
);
export const SettingSlice = createSlice({
  name: "setting",
  initialState,

  extraReducers: {
    [LoggingIn.fulfilled]: (state, action) => {
      state.token = action?.payload?.data?.token;
    },
    [OTPCall.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.token = action?.payload?.data?.data?.token;
    },
    [ForgetPassword.fulfilled]: (state, action) => {
      state.email = action?.meta?.arg?.email;
    },
    [ResendCode.fulfilled]: (state, action) => {
      console.log(action.payload);

      state.token = action?.payload?.data?.data?.token;
    },
  },
});

export default SettingSlice.reducer;
