import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { OTPCall, ResendCode } from "./SettingSlice";

function Otp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [otpValues, setOtpValues] = useState("");
  const [seconds, setSeconds] = useState(5);

  const submitLogin = (e) => {
    e.preventDefault();
    let isnum = /^\d+$/.test(otpValues);
    if (isnum) {
      dispatch(OTPCall({ code: otpValues })).then((res) => {
        console.log(res);
        if (res?.payload?.code === 1) {
          navigate("/reset-password", { replace: true });
        } else {
          setError(res?.payload?.message);
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      });
    } else {
      setError("Kindly enter a valid otp");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("");
    }
  }, [seconds]);

  const resend = () => {
    console.log("incoming");
    dispatch(ResendCode()).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res.payload.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  const handleChange = (val) => {
    setOtpValues(val);
  };

  return (
    <section className="">
      <div className="container-fluid pl-0 ml-0">
        <div className="row align-items-center">
          <div className="col-sm-6 col-md-6 col-lg-6 text-center ml-0 pl-0 sm-mp-lr">
            <div className="logo-bg">
              <div className="">
                <img
                  src="assets/images/image.png"
                  className="img-fluid w-100"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <form className="row login-form">
              <div className="form-group col-sm-12 col-md-12 text-center mb-1">
                <div className="text-center">
                  <img
                    alt=""
                    src="assets/images/OTP-Verification.png"
                    className="forgot-icon modl-icn"
                  />
                </div>
                <h2 className="mdl-ttl">OTP Verification</h2>
                <p className="mdl-sbttl">
                  Please enter 6 digit code which has been send on your
                  <br /> registered email address.
                </p>
              </div>
              <div className="col-sm-12">
                {error !== "" && (
                  <div className="alert alert-danger mt-2">{error}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 mb-4">
                <div className="passcode-wrapper">
                  <OtpInput
                    value={otpValues}
                    onChange={handleChange}
                    numInputs={6}
                    separator={<span> </span>}
                  />
                </div>
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <p className="otp-timing">
                  {seconds !== "" && "Re-send code in 00:"}
                  {seconds}
                </p>
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <button
                  type="submit"
                  onClick={submitLogin}
                  className="btn btn-submit"
                >
                  Verify
                </button>
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                {seconds === "" && (
                  <a
                    href={() => {
                      return false;
                    }}
                    onClick={() => {
                      resend();
                    }}
                    className="forgot-pass cursor-pointer"
                  >
                    Resend Code
                  </a>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Otp;
