import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Constant";

const initialState = {
  list: [],
  count: {
    total: 0,
    cancelled: 0,
    completed: 0,
    upcoming: 0,
    new: 0,
    totals: 1,
  },
  detail: {},
  status: 0,
};

export const GetBookingList = createAsyncThunk(
  "booking list",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${BASE_URL}booking/getBookingList`, {
      params: {
        status: obj.status,
        page: obj.page,
        limit: 10,
        search: obj.search,
      },
      headers: {
        token,
      },
    });
    return response.data;
  }
);

export const GetBookingDetail = createAsyncThunk(
  "booking detail",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${BASE_URL}booking/getBookingDetails`, {
      params: {
        bookingId: obj.id,
      },
      headers: {
        token,
      },
    });
    return response.data;
  }
);

export const ChangeBookingStatus = createAsyncThunk(
  "ChangeBookingStatus",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    let formData = new FormData();

    for (let key in obj) {
      formData.append(key, obj[key]);
    }

    const response = await axios.patch(
      `${BASE_URL}booking/changeBookingSatus`,
      formData,
      {
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const BookingSlice = createSlice({
  name: "bookingSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [GetBookingList.fulfilled]: (state, action) => {
      state.list = action?.payload?.data?.booking_list;
      state.count.total = action?.payload?.data?.total_booking;
      state.count.completed = action?.payload?.data?.total_completed_booking;
      state.count.cancelled = action?.payload?.data?.total_cancelled_booking;
      state.count.totals = action?.payload?.data?.total_booking_with_filter
        ? action?.payload?.data?.total_booking_with_filter
        : 1;
      state.count.new = action?.payload?.data?.total_new_booking;
      state.count.upcoming = action?.payload?.data?.total_upcoming_booking;
    },
    [GetBookingDetail.fulfilled]: (state, action) => {
      console.log(action);
      state.detail = action?.payload?.data;
      state.status = action?.payload?.data?.status;
    },
  },
});

export default BookingSlice.reducer;
