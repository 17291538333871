import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useFormik } from "formik";
import * as yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Upload from "../../component/Upload.js";
import { useDispatch } from "react-redux";
import { AddVendor } from "./VenderSlice";
import GooglePlace from "../../component/GooglePlace.js";

function AddVender() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [toggle, setToggle] = useState(false);

  const submitLogin = () => {
    dispatch(
      AddVendor({
        full_name: formik.values.fullName,
        countryCode: formik.values.countryCode,
        mobile: formik.values.mobileNumber,
        address: formik.values.address,
        password: formik.values.password,
        logo: image,
        email: formik.values.email,
        latitude: formik.values.lat,
        longitude: formik.values.lng,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        navigate(`/add-vender-services/${res?.payload?.data.vendor.id}`, {
          replace: true,
        });
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };
  const validationSchema = yup.object().shape({
    fullName: yup.string().required("Required."),
    address: yup.string().required("Required."),
    mobileNumber: yup.number().required("Required."),
    countryCode: yup.string().required("Required"),
    password: yup.string().required(),
    email: yup.string().email().required(),
  });
  const formik = useFormik({
    initialValues: {
      fullName: "",
      address: "",
      lat: "",
      lng: "",
      countryCode: "",
      mobileNumber: "",
      password: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });

  const handleImage = (data) => {
    setImage(data?.location);
  };

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Add a New Vendor</h2>
              {error && <div className="alert alert-danger mt-2">{error}</div>}
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="upload-btn-wrapper">
                    <img
                      alt=""
                      src={
                        image !== "" ? image : "assets/images/uploadimage.png"
                      }
                      className="avatar"
                    />
                    <button className="btn">
                      <img alt="" src="assets/images/upload.png" />
                    </button>
                    <Upload dataHandler={handleImage} />
                  </div>
                  {formik.touched.image && formik.errors.image ? (
                    <div className="text-red mt-2">{formik.errors.image}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container input-group">
                    <span
                      class="input-group-text border-0 inputGroupText bgGrey"
                      id="basic-addon1"
                    >
                      <img
                        alt=""
                        src="assets/images/First-Name.png"
                        className=""
                      />
                    </span>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="First Name"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("fullName")}
                    />
                  </div>
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="text-red mt-2">
                      {formik.errors.fullName}
                    </div>
                  ) : null}
                </div>

                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container input-group">
                    <span
                      class="input-group-text border-0 inputGroupText bgGrey"
                      id="basic-addon1"
                    >
                      <img
                        alt=""
                        src="assets/images/First-Name.png"
                        className=""
                      />
                    </span>
                    <input
                      className="form-control "
                      placeholder="Email"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("email")}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red mt-2">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container">
                    <PhoneInput
                      inputProps={{
                        name: "mobileNumber",
                      }}
                      country={"in"}
                      placeholder="Enter phone number"
                      containerClass="form-control"
                      searchNotFound="No entries to show."
                      onChange={(phone, country, e, formattedValue) => {
                        if (phone.length > 0) {
                          const raw = phone.slice(country?.dialCode?.length);
                          formik.setFieldValue("mobileNumber", raw);

                          formik.setFieldValue(
                            "countryCode",
                            formattedValue?.split(" ")[0]
                          );
                        } else {
                          formik.setFieldValue("mobileNumber", "");

                          formik.setFieldValue("countryCode", "");
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <div className="text-red mt-2">
                      {formik.errors.mobileNumber}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container">
                    {/* <input
                      type="text"
                      className="form-control pl-5"
                      placeholder="address"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("address")}
                    /> */}
                    {/* <img
                      alt=""
                      src="assets/images/address.png"
                      className="input-img"
                    /> */}
                    <GooglePlace
                      onBlur={formik.handleBlur}
                      value={formik.values?.location}
                      latLong={{
                        lat: formik.values.lat,
                        lng: formik.values.lng,
                      }}
                      name="address"
                      onChange={(val, latLong) => {
                        if (latLong) {
                          formik.setFormikState((prevState) => {
                            return {
                              ...prevState,
                              values: {
                                ...prevState.values,
                                address: val,
                                lat: latLong.lat,
                                lng: latLong.lng,
                              },
                            };
                          });
                        } else {
                          formik.setFormikState((prevState) => {
                            return {
                              ...prevState,
                              values: {
                                ...prevState.values,
                                address: "",
                                lat: "",
                                lng: "",
                              },
                            };
                          });
                        }
                      }}
                    />
                  </div>
                  {formik.touched.address && formik.errors.address ? (
                    <div className="text-red mt-2">{formik.errors.address}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-6 col-lg-6">
                  <div className="input-container input-group">
                    <span
                      class="input-group-text border-0 inputGroupText bgGrey "
                      id="basic-addon1"
                    >
                      <img
                        alt=""
                        src="assets/images/Password.png"
                        className=""
                      />
                    </span>

                    <input
                      type={toggle ? "text" : "password"}
                      className="form-control "
                      placeholder="Password"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("password")}
                    />

                    <span
                      // toggle="#password-field"
                      className="input-group-text pass-view inputGroupTextright bgGrey   border-0 toggle-password cursor-pointer"
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                    ></span>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red mt-2">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={image === ""}
                      className="btn btn-save-chenge mt-2"
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default AddVender;
