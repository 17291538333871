import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Sidebar from "../Sidebar";
import { GetBookingDetail } from "./BookingSlice";

function CancelledBooking() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.booking.detail);
  const getDetails = useCallback(() => {
    dispatch(GetBookingDetail({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }
    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="booking" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Rejected Booking Details</h2>
              <div className="Rejected-Booking-text d-flex justify-content-between align-items-center">
                <h3 className="mt-5">Customer Details</h3>
                <span className="text-danger mt-5 font-weight-bold">
                  Cancelled
                </span>
              </div>
              <div className="student-details-box shadow-box Booking-Details">
                <span>
                  <img
                    alt=""
                    src={
                      data?.user_details?.image !== "" &&
                      data?.user_details?.image !== "url" &&
                      data?.user_details?.image !== "null"
                        ? data?.user_details?.image
                        : "assets/images/default-user.png"
                    }
                    className="img-fluid usr-img"
                  />
                </span>
                <span>
                  <h5>
                    {" "}
                    {`${data?.user_details?.first_name}` +
                      " " +
                      `${data?.user_details?.last_name}`}
                  </h5>
                  <p>
                    <span>
                      <img alt="" src="assets/images/mobile.png" /> Mobile No:
                      {data?.user_details?.mobile
                        ? data?.user_details?.countryCode +
                          data?.user_details?.mobile
                        : "NA"}
                    </span>
                    <span>
                      <img alt="" src="assets/images/address.png" /> Address:{" "}
                      {data?.bookingAddress
                        ? data?.bookingAddress.flat +
                          ", " +
                          data?.bookingAddress?.society +
                          ", " +
                          data?.bookingAddress?.city
                        : "NA"}
                    </span>
                  </p>
                </span>
                <span className="booking-date">
                  {moment(data?.date, "YYYY-MM-DD").format("DD MMM YYYY")}
                </span>
              </div>

              <h3 className="mt-5">Services</h3>
              <div className="row align-items-center">
                {data?.service_list?.length > 0 ? (
                  data?.service_list?.map((service) => {
                    return (
                      <div className="col-sm-6 col-md-6 col-lg-6 mt-3">
                        <div className="revenue-total d-flex justify-content-between">
                          <span className="text-capitalize">
                            {service?.name}
                          </span>
                          <span>
                            <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                            {service?.price}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-sm-6 col-md-6 col-lg-6 mt-3">
                    {" "}
                    <div className="d-flex justify-content-between">
                      No services listed.
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <h3 className="mt-5">Date & Time Slot</h3>
                  <div className="date-time-flex">
                    <span>
                      {moment(data?.date, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </span>
                    <span className="vrt-line"></span>
                    <span>{data?.time_slot}</span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <h3 className="mt-5">Payment Mode</h3>
                  <div className="date-time-flex justify-content-start">
                    <span>
                      {data?.payment_type === "1" ? "Pay Now" : "Pay Later"}
                    </span>
                  </div>
                </div>
              </div>
              <h3 className="mt-5">Cancellation Reason</h3>
              <p>{data?.cancelled_details?.reason || "Not mentioned."}</p>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default CancelledBooking;
