import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Constant";

const initialState = {
  data: {},
  total_booking: 0,
  total_cancelled_booking: 0,
  total_completed_booking: 0,
  total_customers: 0,
  year: [],
};

export const GetEarnings = createAsyncThunk(
  "GetEarnings",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${BASE_URL}admin/dashboardData`, {
      params: {
        year: obj.year,
      },
      headers: {
        token,
      },
    });
    return response.data;
  }
);

export const GetYearList = createAsyncThunk(
  "getYears",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(`${BASE_URL}admin/getYearList`, {
      headers: {
        token,
      },
    });
    return response.data;
  }
);

export const DashbaordSlice = createSlice({
  name: "DashbaordSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [GetEarnings.fulfilled]: (state, action) => {
      console.log(action);
      state.data = action?.payload?.data;
      state.total_customers = action?.payload?.data?.total_customers;
      state.total_booking = action?.payload?.data?.total_booking;
      state.total_cancelled_booking =
        action?.payload?.data?.total_cancelled_booking;
      state.total_completed_booking =
        action?.payload?.data?.total_completed_booking;
    },
    [GetYearList.fulfilled]: (state, action) => {
      console.log(action);
      state.year = action?.payload?.data?.year_list;
    },
  },
});

export default DashbaordSlice.reducer;
