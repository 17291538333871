import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import * as yup from "yup";
import { useCallback, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import {
  AddNewService,
  AddNewSubService,
  GetServicesList,
} from "./ServicesSlice";
import Upload from "../../component/Upload";

function AddSubServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [serviceId, setServiceId] = useState("");
  const services = useSelector((state) => state.services.list);

  const getList = useCallback(() => {
    dispatch(GetServicesList({ page: "", search: "", limit: 10 }));
  }, [dispatch]);

  const submitLogin = () => {
    if (image !== "") {
      if (serviceId !== "") {
        dispatch(
          AddNewService({
            name: formik.values.serviceName,
            image: image,
            price: formik.values.price,
            parent: serviceId,
            type: services?.find(
              (it) => it?.id?.toString() === serviceId?.toString()
            )?.type,
          })
        ).then((res) => {
          if (res?.payload?.code === 1) {
            setError(res.payload.message);
            setTimeout(() => {
              setError("");
              navigate("/manage-sub-services", { replace: true });
            }, 2000);
          }
        });
      } else {
        setError("Service is required");
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } else {
      setError("Image is required");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const validationSchema = yup.object().shape({
    serviceName: yup.string().required("Required."),

    price: yup
      .number()
      .typeError("Price must be a number")
      .required("Required.")
      .positive(),
  });
  const formik = useFormik({
    initialValues: {
      serviceName: "",
      price: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });

  useEffect(() => {
    let isRendered = false;
    if (!isRendered) {
      getList();
    }

    return () => {
      isRendered = false;
    };
  }, [getList]);

  const handleImage = (data) => {
    setImage(data.location);
  };
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="sub-services" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Add a New Sub-Service</h2>
              {error !== "" && (
                <div className="alert alert-danger mt-2">{error}</div>
              )}

              <form
                className="row form-style document-img"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      <img
                        alt=""
                        src={
                          image !== "url" &&
                          image !== null &&
                          image !== "null" &&
                          image !== ""
                            ? image
                            : "assets/images/doc-image.png"
                        }
                      />
                      <p>Upload image</p>
                    </button>
                    <Upload dataHandler={handleImage} />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control pl-5"
                      placeholder="Sub-Service Name"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("serviceName")}
                    />
                    <img
                      alt=""
                      src="assets/images/First-Name.png"
                      className="input-img"
                    />
                  </div>
                  {formik.touched.serviceName && formik.errors.serviceName ? (
                    <div className="alert alert-danger mt-2">
                      {formik.errors.serviceName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control pl-5"
                      placeholder="Price"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("price")}
                    />
                    <img
                      alt=""
                      src="assets/images/currency-drop.png"
                      className="input-img"
                    />
                  </div>
                  {formik.touched.price && formik.errors.price ? (
                    <div className="alert alert-danger mt-2">
                      {formik.errors.price}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="input-container">
                    <select
                      className="form-control pl-5"
                      value={serviceId}
                      onChange={(e) => {
                        setServiceId(e.target.value);
                      }}
                    >
                      <option value="">Select Service</option>
                      {services.length > 0 &&
                        services.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                    </select>
                    <img
                      alt=""
                      src="assets/images/First-Name.png"
                      className="input-img"
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={image === ""}
                      className="btn btn-save-chenge mt-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default AddSubServices;
