import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Constant";

const initialState = {
  list: [],
  detail: null,
  subList: [],
  subTotal: 0,
  subDetail: null,
};

export const GetServicesList = createAsyncThunk("servicesList", async (obj) => {
  const token = await localStorage.getItem("token");
  const response = await axios.get(`${BASE_URL}service/getServiceList`, {
    params: {
      page: obj.page,
      limit: obj.limit,
      search: obj.search,
    },
    headers: {
      token: token,
    },
  });

  return response.data;
});

export const GetSubServicesList = createAsyncThunk(
  "subservicesList",
  async (obj) => {
    const token = await localStorage.getItem("token");
    const response = await axios.get(`${BASE_URL}service/getSubServiceList`, {
      params: {
        page: obj.page,
        limit: 10,
        search: obj.search,
        service_id: obj.service_id,
      },
      headers: {
        token: token,
      },
    });

    return response.data;
  }
);

export const GetServicesDetail = createAsyncThunk(
  "servicesDetail",
  async (obj) => {
    const token = await localStorage.getItem("token");
    const response = await axios.get(`${BASE_URL}service/getServiceDetails`, {
      params: {
        service_id: obj.id,
      },
      headers: {
        token: token,
      },
    });

    return response.data;
  }
);

// export const GetSubServicesDetail = createAsyncThunk(
//   "subservicesDetail",
//   async (obj) => {
//     const token = await localStorage.getItem("token");
//     const response = await axios.get(
//       `${BASE_URL}service/getSubServiceDetails`,
//       {
//         params: {
//           sub_service_id: obj.id,
//         },
//         headers: {
//           token: token,
//         },
//       }
//     );

//     return response.data;
//   }
// );

export const AddNewService = createAsyncThunk("addService", async (obj) => {
  const token = await localStorage.getItem("token");

  let formData = new FormData();

  for (let key in obj) {
    formData.append(key, obj[key]);
  }

  const response = await axios.post(`${BASE_URL}service/addService`, formData, {
    headers: {
      token: token,
    },
  });
  return response.data;
});

// export const AddNewSubService = createAsyncThunk(
//   "addSubService",
//   async (obj) => {
//     const token = await localStorage.getItem("token");

//     let formData = new FormData();

//     for (let key in obj) {
//       formData.append(key, obj[key]);
//     }

//     const response = await axios.post(
//       `${BASE_URL}service/addSubService`,
//       formData,
//       {
//         headers: {
//           token: token,
//         },
//       }
//     );
//     return response.data;
//   }
// );

export const UpdateServicesStatus = createAsyncThunk(
  "updateServiceStatus",
  async (obj) => {
    const token = await localStorage.getItem("token");

    let formData = new FormData();

    for (let key in obj) {
      formData.append(key, obj[key]);
    }

    const response = await axios.patch(
      `${BASE_URL}service/updateStatus`,
      formData,
      {
        headers: {
          token: token,
        },
      }
    );
    return response.data;
  }
);

export const DeleteService = createAsyncThunk("deleteService", async (obj) => {
  const token = await localStorage.getItem("token");

  const response = await axios.delete(`${BASE_URL}service/removeService`, {
    params: {
      service_id: obj.id,
    },
    headers: {
      token: token,
    },
  });
  return response.data;
});

// export const DeleteSubService = createAsyncThunk(
//   "deleteSubService",
//   async (obj) => {
//     const token = await localStorage.getItem("token");

//     const response = await axios.delete(`${BASE_URL}service/removeSubService`, {
//       params: {
//         sub_service_id: obj.id,
//       },
//       headers: {
//         token: token,
//       },
//     });
//     return response.data;
//   }
// );

export const ServicesSlice = createSlice({
  name: "name",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [GetServicesList.fulfilled]: (state, action) => {
      state.list = action.payload.data.service;
      state.total = action.payload.data.total_service;
    },
    [GetServicesDetail.fulfilled]: (state, action) => {
      state.detail = action.payload.data.service;
    },
    [GetSubServicesList.fulfilled]: (state, action) => {
      state.subList = action.payload.data.sub_service;
      state.subTotal = action.payload.data.total_sub_service;
    },
  },
});

export default ServicesSlice.reducer;
