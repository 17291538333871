import { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Sidebar from "../Sidebar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Upload from "../../component/Upload";
import { useDispatch, useSelector } from "react-redux";
import { AddNewUser, GetUserDetail } from "./UserSlice";

function EditUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [image, setImage] = useState("");

  const data = useSelector((state) => state.user.userDetail);

  const submitLogin = () => {
    if (image !== "") {
      dispatch(
        AddNewUser({
          first_name: formik.values.firstName,
          last_name: formik.values.lastName,
          countryCode: formik.values.countryCode,
          mobile: formik.values.mobileNumber,
          email: formik.values.email,
          image: image,
          userId: id,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          setError(res.payload.message);
          setTimeout(() => {
            setError("");
            navigate("/manage-user", { replace: true });
          }, 3000);
        } else {
          setError(res.payload.message);
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      });
    } else {
      setError("Image is required");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Required."),

    lastName: yup.string().required("Required."),
    email: yup
      .string()
      .email("Kindly enter a valid email.")
      .required("Email is required."),
    mobileNumber: yup.string().required("Required."),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      mobileNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });

  const getDetails = useCallback(() => {
    dispatch(GetUserDetail({ id: id })).then((res) => {
      if (res?.payload?.code === 1) {
        setImage(res?.payload?.data?.user?.image);
        formik.setFieldValue("firstName", res?.payload?.data?.user?.first_name);
        formik.setFieldValue("lastName", res.payload.data.user.last_name);
        formik.setFieldValue("mobileNumber", res.payload.data.user.mobile);
        formik.setFieldValue("countryCode", res.payload.data.user.countryCode);
        formik.setFieldValue("email", res.payload.data.user.email);
      }
    });
  }, [id, dispatch]);

  console.log(data, "Data");
  useEffect(() => {
    getDetails();
  }, [getDetails]);

  // const validateMobile = async (e) => {
  //   const countryCodeL = formatPhoneNumberIntl(e).split(" ")[0];
  //   const mobileL = e.replace(countryCodeL, "");

  //   formik.setFieldValue("countryCode", countryCodeL);
  //   formik.setFieldValue("mobileNumber", mobileL);
  // };

  const uploadImage = (img) => {
    setImage(img?.location);
  };

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="user" />
          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Edit User</h2>

              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="upload-btn-wrapper">
                    <img
                      alt=""
                      src={image ? image : "assets/images/avatar.png"}
                      className="avatar"
                      // height="20%"
                      // width="20%"
                    />
                    <button className="btn">
                      <img alt="" src={"assets/images/upload.png"} />
                    </button>
                    <Upload dataHandler={uploadImage} />
                  </div>
                  {formik.touched.image && formik.errors.image ? (
                    <div className="text-red mt-2">{formik.errors.image}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control pl-5"
                      placeholder="First Name"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("firstName")}
                    />
                    <img
                      alt=""
                      src="assets/images/First-Name.png"
                      className="input-img"
                    />
                  </div>
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-red mt-2">
                      {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control pl-5"
                      placeholder="Last Name"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("lastName")}
                    />
                    <img
                      alt=""
                      src="assets/images/First-Name.png"
                      className="input-img"
                    />
                  </div>
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-red mt-2">
                      {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container">
                    <input
                      type="email"
                      className="form-control pl-5"
                      placeholder="Email Address"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("email")}
                    />
                    <img
                      alt=""
                      src="assets/images/add-email.png"
                      className="input-img"
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-red mt-2">{formik.errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container">
                    {/* <select className="tel-code">
                      <option>+91</option>
                      <option>+92</option>
                    </select>
                    <input
                      type="tel"
                      className="form-control pl-25"
                      placeholder="Mobile Number"
                    />
                    <img
                      alt=""
                      src="assets/images/mobile.png"
                      className="input-img"
                    /> */}
                    <PhoneInput
                      placeholder="Enter phone number"
                      containerClass="form-control"
                      searchNotFound="No entries to show."
                      value={
                        formik.values.countryCode + formik.values.mobileNumber
                      }
                      onChange={(phone, country, e, formattedValue) => {
                        if (phone.length > 0) {
                          const raw = phone.slice(country?.dialCode?.length);
                          console.log(raw);
                          formik.setFieldValue("mobileNumber", raw);

                          formik.setFieldValue(
                            "countryCode",
                            formattedValue?.split(" ")[0]
                          );
                        } else {
                          formik.setFieldValue("mobileNumber", "");

                          formik.setFieldValue("countryCode", "");
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <div className="text-red mt-2">
                      {formik.errors.mobileNumber}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="text-center">
                    <button type="submit" className="btn btn-save-chenge mt-2">
                      Save
                    </button>
                  </div>
                  {error !== "" && (
                    <div className="alert alert-danger mt-2">{error}</div>
                  )}
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
