import { Route, Routes, Outlet } from "react-router-dom";
import Booking from "../features/Booking/Booking";
import CancelledBooking from "../features/Booking/CancelledBooking";
import ManageBooking from "../features/Booking/ManageBooking";
import Dashbaord from "../features/dashboard/Dashbaord";
import AddNotification from "../features/Notification/AddNotification";
import ManageNotification from "../features/Notification/ManageNotification";
import ViewNotification from "../features/Notification/ViewNotification";
import AddServices from "../features/Services/AddServices";
import AddSubServices from "../features/Services/AddSubServices";
import EditServices from "../features/Services/EditServices";
import EditSubService from "../features/Services/EditSubService";
import ManageServices from "../features/Services/ManageServices";
import ManageSubServices from "../features/Services/ManageSubServices";
import ChangePassword from "../features/setting/ChangePassword";
import ForgotPassword from "../features/setting/Forgotpassword";
import Login from "../features/setting/Login";
import Otp from "../features/setting/Otp";
import ResetPassword from "../features/setting/ResetPassword";
import AddSubscription from "../features/Subscription/AddSubscription";
import EditSubscription from "../features/Subscription/EditSubscription";
import SubscriptionPlan from "../features/Subscription/SubscriptionPlan";
import ViewSubscription from "../features/Subscription/ViewSubscription";
import AddUser from "../features/User/AddUser";
import EditUser from "../features/User/EditUser";
import ManageUser from "../features/User/ManageUser";
import ViewUser from "../features/User/ViewUser";
import AddVender from "../features/Vender/AddVender";
import AddVenderAccountDetail from "../features/Vender/AddVenderAccountDetail";
import AddVenderAvailability from "../features/Vender/AddVenderAvailability";
import AddVenderDocument from "../features/Vender/AddVenderDocument";
import AddVenderServices from "../features/Vender/AddVenderServices";
import EditVenderAccountDetail from "../features/Vender/EditVenderAccountDetail";
import EditVenderAvailability from "../features/Vender/EditVenderAvailability";
import EditVenderDocument from "../features/Vender/EditVenderDocument";
import EditVenderServices from "../features/Vender/EditVenderServices";
import EditVendor from "../features/Vender/EditVendor";
import ManageVender from "../features/Vender/ManageVender";
import VenderDetail from "../features/Vender/VenderDetail";
import ViewVender from "../features/Vender/ViewVender";
import PrivateRoutes from "../routes/PrivateRoutes";

const Routers = [
  {
    path: "manage-user",
    element: ManageUser,
  },
  {
    path: "add-user",
    element: AddUser,
  },
  {
    path: "view-user/:id",
    element: ViewUser,
  },
  {
    path: "edit-user/:id",
    element: EditUser,
  },
  {
    path: "manage-vender",
    element: ManageVender,
  },
  {
    path: "add-vender",
    element: AddVender,
  },
  {
    path: "add-vender-services/:id",
    element: AddVenderServices,
  },
  {
    path: "add-vender-availability/:id",
    element: AddVenderAvailability,
  },
  {
    path: "add-vender-documents/:id",
    element: AddVenderDocument,
  },
  {
    path: "add-vender-account-detail/:id",
    element: AddVenderAccountDetail,
  },

  {
    path: "view-vender/:id",
    element: ViewVender,
  },
  {
    path: "edit-vender/:id",
    element: EditVendor,
  },
  {
    path: "edit-vender-services/:id",
    element: EditVenderServices,
  },
  {
    path: "edit-vender-availability/:id",
    element: EditVenderAvailability,
  },
  {
    path: "edit-vender-documents/:id",
    element: EditVenderDocument,
  },
  {
    path: "edit-vender-account-detail/:id",
    element: EditVenderAccountDetail,
  },
  {
    path: "vender-detail/:id",
    element: VenderDetail,
  },
  {
    path: "add-services",
    element: AddServices,
  },
  {
    path: "manage-services",
    element: ManageServices,
  },
  {
    path: "edit-service/:id",
    element: EditServices,
  },
  {
    path: "manage-sub-services",
    element: ManageSubServices,
  },
  {
    path: "add-sub-services",
    element: AddSubServices,
  },
  {
    path: "edit-sub-service/:id",
    element: EditSubService,
  },
  {
    path: "manage-booking",
    element: ManageBooking,
  },
  {
    path: "cancelled-booking/:id",
    element: CancelledBooking,
  },
  {
    path: "booking/:id",
    element: Booking,
  },
  {
    path: "manage-notification",
    element: ManageNotification,
  },
  {
    path: "view-notification/:id",
    element: ViewNotification,
  },
  {
    path: "add-notification",
    element: AddNotification,
  },
  {
    path: "subscription-plan",
    element: SubscriptionPlan,
  },
  {
    path: "view-subscription/:id",
    element: ViewSubscription,
  },
  {
    path: "add-subscription",
    element: AddSubscription,
  },
  {
    path: "edit-subscription/:id",
    element: EditSubscription,
  },
  {
    path: "change-password",
    element: ChangePassword,
  },
  {
    path: "dashboard",
    element: Dashbaord,
  },
];

export default Routers;

export function RenderRoutes({ routers }) {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="otp" element={<Otp />} />
      <Route path="reset-password" element={<ResetPassword />} />

      {routers.map((route, i) => {
        return (
          <Route
            key={i}
            path={route.path}
            element={
              <PrivateRoutes>
                <route.element />
              </PrivateRoutes>
            }
          ></Route>
        );
      })}

      <Route path="*" element={<Login />} />
    </Routes>
  );
}
