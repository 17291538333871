import { useState } from "react";
import Sidebar from "../Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { AddVendorAccount } from "./VenderSlice";

function AddVenderAccountDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState("");

  const submitLogin = () => {
    dispatch(
      AddVendorAccount({
        bank_name: formik.values.bankName,
        account_holder_name: formik.values.accountHolderName,
        account_number: formik.values.accountNumber,
        IFSC_code: formik.values.ifscCode,
        vendorId: id,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        navigate(`/manage-vender`, { replace: true });
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };
  const validationSchema = yup.object().shape({
    bankName: yup.string().required("Required."),
    accountHolderName: yup.string().required("Required."),

    accountNumber: yup
      .number()
      .positive("Must be a valid account number.")
      .required("Required"),
    confirmAccountNumber: yup
      .number()
      .oneOf(
        [yup.ref("accountNumber"), null],
        "Kindly confirm you account number."
      )
      .required("Required"),
    ifscCode: yup.string().required("Required."),
  });
  const formik = useFormik({
    initialValues: {
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      confirmAccountNumber: "",
      ifscCode: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Add a New Vendor</h2>
              {error !== "" && (
                <div className="alert alert-danger mt-2">{error}</div>
              )}
              <form className="row form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <h2>Bank Account</h2>
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container input-group">
                    <span
                      class="input-group-text border-0 inputGroupText bgGrey "
                      id="basic-addon1"
                    >
                      <img
                        alt=""
                        src="assets/images/Bank-Name.png"
                        className=""
                      />
                    </span>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Bank Name"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("bankName")}
                    />
                  </div>
                  {formik.touched.bankName && formik.errors.bankName ? (
                    <div className="text-red mt-2">
                      {formik.errors.bankName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                  <div className="input-container input-group">
                    <span
                      class="input-group-text border-0 inputGroupText bgGrey "
                      id="basic-addon1"
                    >
                      <img
                        alt=""
                        src="assets/images/First-Name.png"
                        className=""
                      />
                    </span>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Account Holder's Name"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("accountHolderName")}
                    />
                  </div>
                  {formik.touched.accountHolderName &&
                  formik.errors.accountHolderName ? (
                    <div className="text-red mt-2">
                      {formik.errors.accountHolderName}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                <div className="input-container input-group">
                <span
                  class="input-group-text border-0 inputGroupText bgGrey "
                  id="basic-addon1"
                >
                  <img
                    alt=""
                    src="assets/images/Account-number.png"
                    className=""
                  />
                </span>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Account number"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("accountNumber")}
                    />
                    
                  </div>
                  {formik.touched.accountNumber &&
                  formik.errors.accountNumber ? (
                    <div className="text-red mt-2">
                      {formik.errors.accountNumber}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                <div className="input-container input-group">
                <span
                  class="input-group-text border-0 inputGroupText bgGrey "
                  id="basic-addon1"
                >
                  <img
                    alt=""
                    src="assets/images/Account-number.png"
                    className=""
                  />
                </span>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="Confirm Account number"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("confirmAccountNumber")}
                    />
                    
                  </div>
                  {formik.touched.confirmAccountNumber &&
                  formik.errors.confirmAccountNumber ? (
                    <div className="text-red mt-2">
                      {formik.errors.confirmAccountNumber}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6">
                <div className="input-container input-group">
                <span
                  class="input-group-text border-0 inputGroupText bgGrey "
                  id="basic-addon1"
                >
                  <img
                    alt=""
                    src="assets/images/IFSCCode.png"
                    className=""
                  />
                </span>
                    <input
                      type="text"
                      className="form-control "
                      placeholder="IFSC Code"
                      onBlur={formik.handleBlur}
                      {...formik.getFieldProps("ifscCode")}
                    />
                  </div>
                  {formik.touched.ifscCode && formik.errors.ifscCode ? (
                    <div className="text-red mt-2">
                      {formik.errors.ifscCode}
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="text-center">
                    <button type="submit" className="btn btn-save-chenge mt-2">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default AddVenderAccountDetail;
