import React, { useEffect, useState, useCallback } from "react";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import DeleteModal from "../../Modal/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  ActivateDeactivateVendor,
  DeleteVendor,
  GetVenderList,
  UpdateVendorStatus,
} from "./VenderSlice";

function ManageVender() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const active = useSelector((state) => state.vender.active);
  const inActive = useSelector((state) => state.vender.inActive);
  const approved = useSelector((state) => state.vender.approved);
  const rejected = useSelector((state) => state.vender.rejected);
  const total = useSelector((state) => state.vender?.total);
  const totals = useSelector((state) => state.vender?.totals);
  const [searchValue, setSearchValue] = useState("");
  const data = useSelector((state) => state.vender.list);
  const [activePage, setActivePage] = useState(1);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(0);
  const [delId, setDelId] = useState("");

  const Paginate = (val) => {
    setActivePage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handleDelete = (id) => {
    setDelId(id);
    handleShow();
  };

  const confirmDelete = () => {
    dispatch(DeleteVendor({ id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res?.payload?.message);
        getList();
        handleShow();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError(res?.payload?.message);
        getList();
        handleShow();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const handleStatus = (data) => {
    dispatch(UpdateVendorStatus(data)).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res?.payload?.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError(res?.payload?.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const ToggleVendor = (data) => {
    dispatch(ActivateDeactivateVendor(data)).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res?.payload?.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError(res?.payload?.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const getList = useCallback(() => {
    dispatch(
      GetVenderList({ page: activePage, search: searchValue, status: status })
    );
  }, [dispatch, activePage, searchValue, status]);

  useEffect(() => {
    let isRendered = false;
    if (!isRendered) {
      getList();
    }
    return () => {
      isRendered = false;
    };
  }, [getList]);

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body">
              <div className="row align-items-center col-rever">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <ul className="nav nav-tabs text-center group-tabs">
                    <li className="nav-item ">
                      <span className="nav-link revenue-total">
                        <p>
                          <img
                            alt=""
                            src="assets/images/total-vendor.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{total && total}</h5>
                        <p>Total Vendors</p>
                      </span>
                    </li>
                    <li className="nav-item ">
                      <span className="nav-link revenue-total">
                        <p>
                          <img
                            alt=""
                            src="assets/images/total-vendor.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{active && active}</h5>
                        <p>Active Vendors</p>
                      </span>
                    </li>
                    <li className="nav-item ">
                      <span className="nav-link revenue-total mr-0">
                        <p>
                          <img
                            alt=""
                            src="assets/images/total-vendor.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{inActive && inActive}</h5>
                        <p>Inactive Vendors</p>
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <form className="has-search">
                      <span className="form-search-icon form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search vendor by name"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                      />
                    </form>
                    <Link to="/add-vender" className="add-new">
                      + Add New
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-9 col-lg-6">
                  <ul className="nav nav-tabs text-center group-tabs vendor-tabs">
                    <li className="nav-item ">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#new"
                        onClick={() => {
                          setStatus(0);
                          setActivePage(1);
                        }}
                      >
                        New
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Approved"
                        onClick={() => {
                          setStatus(1);
                          setActivePage(1);
                        }}
                      >
                        Approved
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a
                        className="nav-link mr-0"
                        data-toggle="tab"
                        href="#Rejected"
                        onClick={() => {
                          setStatus(2);
                          setActivePage(1);
                        }}
                      >
                        Rejected
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-sm-12">
                  {error !== "" && (
                    <div className="alert alert-danger mt-2">{error}</div>
                  )}
                  <div className="tab-content">
                    <div id="new" className="tab-pane active">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">photo</th>
                              <th scope="col">full name</th>
                              <th scope="col">email address</th>
                              <th scope="col">mobile no.</th>
                              <th scope="col">address</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <img
                                        alt=""
                                        src={
                                          item.logo.includes("http")
                                            ? item.logo
                                            : "assets/images/default-user.png"
                                        }
                                        className="table-img"
                                      />
                                    </td>
                                    <td>{item?.full_name}</td>
                                    <td className="text-lowercase">
                                      {item.email}
                                    </td>
                                    <td>{item?.countryCode + item.mobile}</td>
                                    <td>{item.address}</td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn" title="View">
                                          <Link
                                            to={`/vender-detail/${item.id}`}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Approve"
                                          onClick={() => {
                                            handleStatus({
                                              status: 1,
                                              vendorId: item.id,
                                            });
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/approve.png"
                                            />
                                          </span>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Reject"
                                          onClick={() => {
                                            handleStatus({
                                              status: 2,
                                              vendorId: item.id,
                                            });
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/reject.png"
                                            />
                                          </span>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Delete"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0 ? totals : Number(total)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                    <div id="Approved" className="tab-pane fade">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">photo</th>
                              <th scope="col">full name</th>
                              <th scope="col">email address</th>
                              <th scope="col">mobile no.</th>
                              <th scope="col">address</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <img
                                        alt=""
                                        src={
                                          item.logo.includes("http")
                                            ? item.logo
                                            : "assets/images/default-user.png"
                                        }
                                        className="table-img"
                                      />
                                    </td>
                                    <td>{item?.full_name}</td>
                                    <td className="text-lowercase">
                                      {item.email}
                                    </td>
                                    <td>{item?.countryCode + item.mobile}</td>
                                    <td>{item.address}</td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn" title="View">
                                          <Link
                                            to={`/vender-detail/${item.id}`}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn" title="Edit">
                                          <Link to={`/edit-vender/${item.id}`}>
                                            <img
                                              alt=""
                                              src="assets/images/edit.png"
                                            />
                                          </Link>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Delete"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Activate/Deactivate"
                                          onClick={() => {
                                            ToggleVendor({
                                              status:
                                                item.isActive === true
                                                  ? false
                                                  : true,
                                              vendorId: item.id,
                                            });
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={item.isActive}
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? Number(totals)
                                : Number(approved)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                    <div id="Rejected" className="tab-pane fade">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">photo</th>
                              <th scope="col">full name</th>
                              <th scope="col">email address</th>
                              <th scope="col">mobile no.</th>
                              <th scope="col">address</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <img
                                        alt=""
                                        src={
                                          item.logo.includes("http")
                                            ? item.logo
                                            : "assets/images/default-user.png"
                                        }
                                        className="table-img"
                                      />
                                    </td>
                                    <td>{item?.full_name}</td>
                                    <td className="text-lowercase">
                                      {item.email}
                                    </td>
                                    <td>{item?.countryCode + item.mobile}</td>
                                    <td>{item.address}</td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button
                                          className="btn"
                                          title="Accept"
                                          onClick={() => {
                                            handleStatus({
                                              status: 1,
                                              vendorId: item.id,
                                            });
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/approve.png"
                                            />
                                          </span>
                                        </button>
                                        <button
                                          className="btn"
                                          title="Delete"
                                          onClick={() => {
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <span>
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={
                              searchValue.length > 0
                                ? Number(totals)
                                : Number(rejected)
                            }
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {delId && (
        <DeleteModal
          show={show}
          page="Vendor Service"
          setShow={handleShow}
          confirmDelete={confirmDelete}
        />
      )}
    </div>
  );
}

export default ManageVender;
