import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Sidebar from "../Sidebar";
import Upload from "../../component/Upload";
import { AddPlan, DeleteFeature, PlanDetail } from "./PlanSlice";

function EditSubscription() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [features, setFeatures] = useState([]);

  const getDetails = useCallback(() => {
    dispatch(PlanDetail({ id: id })).then((res) => {
      console.log(res);
      if (res?.payload?.code === 1) {
        let d = res?.payload?.data.plan_details;
        formik.setFieldValue("name", d?.name);
        formik.setFieldValue("price", d?.price);
        formik.setFieldValue("validity", d?.validity);
        formik.setFieldValue("image", d?.image);
        setFeatures(
          d?.features.map((item) => {
            return { id: item.id, feature: item.features };
          })
        );
      }
    });
  }, [dispatch, id]);

  const submitLogin = () => {
    let featureIsNotEmpty = features.every((item) => item.feature !== "");
    if (featureIsNotEmpty) {
      dispatch(
        AddPlan({
          features: features,
          name: formik.values.name,
          price: formik.values.price,
          validity: formik.values.validity,
          image: formik.values.image,
          planId: id,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          setError(res?.payload?.message);
          setTimeout(() => {
            setError("");
            navigate("/subscription-plan", { replace: true });
          }, 3000);
        } else {
          setError(res?.payload?.message);
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      });
    } else {
      setError("Kindly add all features.");
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("Required."),

    price: yup.number().required("Required."),

    validity: yup.date().min(new Date()).required("Required"),
    image: yup.string().required("Required."),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      price: "",
      validity: "",
      image: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });

  const handleFeature = (index, value) => {
    let arr = [...features];

    arr.map((item) => {
      if (arr.indexOf(item) === index) {
        console.log(item, "Item");
        item.feature = value;
      }
      return item;
    });

    setFeatures(arr);
  };

  const handleDelete = (data) => {
    // let arr = [...features];
    // arr.splice(index, 1);
    // setFeatures(arr);

    dispatch(DeleteFeature(data)).then((res) => {
      if (res?.payload?.code === 1) {
        setError("");
        getDetails();
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
          getDetails();
        }, 3000);
      }
    });
  };

  const handleImage = (data) => {
    formik.setFieldValue("image", data?.location);
  };

  const addMoreFeature = () => {
    setFeatures([...features, { feature: "" }]);
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }
    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="subscription" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Add New Plan</h2>

              <form className="form-style" onSubmit={formik.handleSubmit}>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 text-center">
                  <div className="upload-btn-wrapper">
                    <img
                      alt=""
                      src={
                        formik.values.image !== "" &&
                        formik.values.image !== "url" &&
                        formik.values.image !== "null"
                          ? formik.values.image
                          : "assets/images/uploadimage.png"
                      }
                      className="avatar"
                    />
                    <button className="btn">
                      <img alt="" src="assets/images/upload.png" />
                    </button>
                    <Upload dataHandler={handleImage} />
                  </div>
                  {formik.touched.image && formik.errors.image ? (
                    <div className="text-red mt-2">{formik.errors.image}</div>
                  ) : null}
                </div>

                <div className="list-message-box">
                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Subscription Name"
                        onBlur={formik.handleBlur}
                        {...formik.getFieldProps("name")}
                      />
                      <img
                        alt=""
                        src="assets/images/Subscription-Name.png"
                        className="input-img"
                      />
                    </div>
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-red mt-2">{formik.errors.name}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Price"
                        onBlur={formik.handleBlur}
                        {...formik.getFieldProps("price")}
                      />
                      <img
                        alt=""
                        src="assets/images/currency-drop.png"
                        className="input-img"
                      />
                    </div>
                    {formik.touched.price && formik.errors.price ? (
                      <div className="text-red mt-2">{formik.errors.price}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <div className="input-container">
                      <input
                        type="text"
                        className="form-control pl-5"
                        placeholder="Validity"
                        onFocus={(e) => {
                          e.currentTarget.type = "date";
                        }}
                        onBlur={(e) => {
                          e.currentTarget.type = "";
                          formik.handleBlur();
                        }}
                        {...formik.getFieldProps("validity")}
                      />
                      <img
                        alt=""
                        src="assets/images/Validity.png"
                        className="input-img"
                      />
                    </div>
                    {formik.touched.validity && formik.errors.validity ? (
                      <div className="text-red mt-2">
                        {formik.errors.validity}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <h3>Features</h3>
                  </div>

                  {features?.map((item, i) => {
                    return (
                      <div className="form-group">
                        <div
                          className="input-container"
                          style={{
                            position: "relative",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control pl-5"
                            placeholder="Write here"
                            value={item.feature}
                            onChange={(e) => {
                              let val = e.target.value;
                              handleFeature(i, val);
                            }}
                          />
                          <img
                            alt=""
                            src="assets/images/feature.png"
                            className="input-img"
                          />
                          <img
                            alt=""
                            src="assets/images/delete.png"
                            className="deleteFeature cursor-pointer"
                            onClick={() => {
                              handleDelete({ id: id, fId: item.id });
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="form-group">
                  <span id="btnAddtoList" onClick={addMoreFeature}>
                    + Add more features
                  </span>
                </div>
                <div className="form-group">
                  <div className="text-center">
                    <button type="submit" className="btn btn-save-chenge mt-2">
                      Save
                    </button>
                  </div>
                </div>
                {error && (
                  <div className="alert alert-danger mt-2">{error}</div>
                )}
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default EditSubscription;
