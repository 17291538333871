import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { GetServicesList, GetSubServicesList } from "../Services/ServicesSlice";
import Select from "react-select";
import { AddVendorServices } from "./VenderSlice";

function AddVenderServices() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState("");
  const servicesList = useSelector((state) => state.services.list);
  const [service, setService] = useState([]);
  const [obj, setObj] = useState({});
  const [options, setOptions] = useState({});

  const submit = (dataa) => {
    dispatch(
      AddVendorServices({
        service_data: dataa,
        vendorId: id,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        navigate(`/add-vender-availability/${id}`);
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const getDatas = () => {
    let datas = [];
    for (const key in options) {
      if (Object.hasOwnProperty.call(options, key)) {
        const element = options[key];
        element.data.map((item) => {
          datas.push({
            service_id: item.id,
            price: item.price,
            it_service_data: [],
            transport_service_data: [],
          });
        });
      }
    }
    submit(datas);
  };

  const handleChange = (e, data) => {
    const { name, checked } = e.target;
    let arr = [...service];
    if (!checked) {
      if (arr.indexOf(name) !== -1) {
        arr.splice(arr.indexOf(name), 1);
      }
    } else if (checked) {
      if (arr.indexOf(name) === -1) {
        arr.push(name);
        handleSubservices(arr, data);
      }
    }
    setService(arr);
  };

  const handleSubservices = (arry, data) => {
    getSubList(data.id, data.name);
  };

  const getSubList = (id, name) => {
    dispatch(GetSubServicesList({ page: "", search: "", service_id: id })).then(
      (res) => {
        if (res?.payload.code === 1) {
          setObj({
            ...obj,
            [id]: { data: res.payload.data.sub_service, name },
          });
        }
      }
    );
  };

  const getList = useCallback(() => {
    dispatch(GetServicesList({ page: "", search: "", limit: "" }));
  }, [dispatch]);

  const handleSubs = (id, val, itemId) => {
    let arr = options[id].data.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          price: val,
        };
      } else {
        return item;
      }
    });

    setOptions({
      ...options,
      [id]: {
        ...options[id],
        data: arr,
      },
    });
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }
    return () => {
      isRendered = false;
    };
  }, [getList]);

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Add a New Vendor</h2>
              {error && <div className="alert alert-danger mt-2">{error}</div>}

              <form className="row form-style-ven">
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <h2>Services</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <ul className="services-checkbox">
                    {servicesList && servicesList?.length > 0 ? (
                      servicesList.map((item, i) => {
                        return (
                          <li key={item.id}>
                            <input
                              type="checkbox"
                              id={"myCheckbox1" + item.id}
                              name={item.id}
                              onChange={(e) => {
                                handleChange(e, {
                                  key: i,
                                  id: item.id,
                                  price: item.price,
                                  name: item.name,
                                });
                              }}
                            />
                            <label htmlFor={`myCheckbox1${item.id}`}>
                              <img
                                alt=""
                                src={
                                  item.image &&
                                  item.image !== null &&
                                  item.image !== "url" &&
                                  item.image !== "" &&
                                  item.image !== "null"
                                    ? item.image
                                    : "assets/images/sub-service.png"
                                }
                              />
                            </label>
                            <h6>{item?.name}</h6>
                          </li>
                        );
                      })
                    ) : (
                      <span>No services available.</span>
                    )}
                  </ul>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <h2>Select Sub-Service</h2>
                </div>
                {service.length > 0 &&
                  service.map((item) => {
                    return (
                      <div className="form-group col-sm-6 col-md-6 col-lg-6">
                        <h5>{obj[item]?.name}</h5>
                        <Select
                          classNamePrefix="react-select"
                          className="form-control"
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={obj[item]?.data}
                          placeholder="Select Sub-Services"
                          isMulti={true}
                          onChange={(val) => {
                            if (val.length > 0) {
                              setOptions({
                                ...options,
                                [item]: {
                                  data: val,
                                  name: obj[item].name,
                                },
                              });
                            } else {
                              setOptions({
                                ...options,
                                [item]: {
                                  data: [],
                                  name: obj[item].name,
                                },
                              });
                            }
                          }}
                        />

                        {options &&
                          options[item]?.data?.map((subs, i) => {
                            return (
                              <div className="row align-items-center sub-ser">
                                <div className="col-sm-6 col-md-12 col-lg-6 mt-3">
                                  <h6>{subs?.name}</h6>
                                </div>
                                <div className="col-sm-6 col-md-12 col-lg-6 mt-3">
                                  <div className="input-container input-group inputGroupText inputGroupTextright shadow">
                                    <span
                                      class="input-group-text border-0 inputGroupText bg-white "
                                      id="basic-addon1"
                                    >
                                      <img
                                        alt=""
                                        src="assets/images/currency.png"
                                        className=""
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control "
                                      placeholder="Add Price"
                                      value={subs.price}
                                      onChange={(e) => {
                                        handleSubs(
                                          item,

                                          e.target.value,

                                          subs.id
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}

                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="text-center">
                    <span
                      onClick={() => {
                        getDatas();
                      }}
                    >
                      <span className="btn btn-save-chenge mt-2">
                        Save & Next
                      </span>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default AddVenderServices;
