import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetUserList } from "../User/UserSlice";

function SelectUserModal({ show, handleShow, onDone }) {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  // const data = useSelector((state) => state?.user?.list);
  const [arryOfId, setArryOfId] = useState([]);
  const [data, setData] = useState([]);

  const getUsers = useCallback(() => {
    dispatch(GetUserList({ status: "1", search: searchValue })).then((res) => {
      if (res?.payload?.code === 1) {
        setData(
          res?.payload?.data?.user?.map((item) => {
            return { ...item, checked: false };
          })
        );
      }
    });
  }, [dispatch, searchValue]);

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getUsers();
    }
    return () => {
      isRendered = false;
    };
  }, [getUsers]);

  const handleCheckBox = (e, ind) => {
    const val = e.target.id;
    const checked = e.target.checked;
    const arr = [...arryOfId];

    if (checked) {
      if (arr.indexOf(val) === -1) {
        setArryOfId([...arryOfId, val]);
      }
      let newData = data?.map((item, i) => {
        if (i === ind) {
          return { ...item, checked: true };
        }
        return item;
      });
      setData(newData);
    } else if (checked === false) {
      if (arr.indexOf(val) !== -1) {
        setArryOfId(arr.filter((item) => item !== val));
      }
      let newData = data?.map((item, i) => {
        if (i === ind) {
          return { ...item, checked: false };
        }
        return item;
      });
      setData(newData);
    }
  };

  const Comp = () => {
    return (
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content b-r-30">
          <div
            className="modal-body"
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <button
                type="button"
                className="close clse"
                onClick={() => {
                  handleShow();
                  onDone([]);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h3 className="mt-4 text-center">Select Customers</h3>
              <div className="form-group">
                <div className="has-search shadow-box b-r-30">
                  <span className="form-search-icon form-control-feedback"></span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Users"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    autoFocus
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                className="form-group user-checkbox"
                style={{
                  overflowY: "auto",
                  height: "50vh",
                }}
              >
                {data?.map((user, i) => {
                  return (
                    <p
                      key={i}
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`${user?.id}`}
                        className="styled-checkbox"
                        checked={user?.checked}
                        // checked={arryOfId?.indexOf(user.id) !== -1}
                        onChange={(e) => {
                          handleCheckBox(e, i);
                        }}
                      />
                      <label
                        htmlFor={`${user?.id}`}
                        style={{
                          position: "relative",
                        }}
                      >
                        <img
                          src={user?.image || "assets/images/default-user.png"}
                          className="checkbox-img"
                          alt=""
                        />{" "}
                        {user?.first_name && user?.last_name
                          ? user?.first_name + " " + user?.last_name
                          : "First-floppy User"}
                      </label>
                    </p>
                  );
                })}
              </div>
              <div className="form-group text-center">
                <button
                  type="button"
                  onClick={() => {
                    onDone(arryOfId);
                    handleShow();
                  }}
                  className="btn btn-save-chenge mt-2"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <Modal show={show} onHide={handleShow} dialogAs={Comp} />;
}

export default SelectUserModal;
