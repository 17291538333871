import { useState } from "react";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Upload from "../../component/Upload";
import { useDispatch } from "react-redux";
import { AddVendorDocuments } from "./VenderSlice";

function AddVenderDocument() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [error, setError] = useState("");
  const [arr, setArr] = useState([0]);
  const submitLogin = () => {
    dispatch(
      AddVendorDocuments({
        about_store: formik.values.about,
        pan_card: formik.values.pan,
        id_proof: formik.values.id,
        aadhar_proof: formik.values.aadhar,
        gst_proof: formik.values.gst,
        vendorId: id,
      })
    ).then((res) => {
      if (res?.payload?.code === 1) {
        navigate(`/add-vender-account-detail/${id}`, { replace: true });
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };
  const validationSchema = yup.object().shape({
    about: yup.string().required("Required."),

    pan: yup.string().required("File is required"),
    gst: yup.string().required("File is required"),
    id: yup.string().notRequired().nullable(),
    aadhar: yup.string().required("File is required"),
  });
  const formik = useFormik({
    initialValues: {
      about: "",
      pan: "",
      gst: "",
      aadhar: "",
      id: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });

  const handleImage = (data) => {
    formik.setFieldValue("pan", data.location);
  };
  const handleGstProof = (data) => {
    formik.setFieldValue("gst", data.location);
  };
  const handleIdProof = (data) => {
    formik.setFieldValue("id", data.location);
  };
  const handleAadharProof = (data) => {
    formik.setFieldValue("aadhar", data.location);
  };
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Add a New Vendor</h2>
              {error !== "" && (
                <div className="alert alert-danger mt-2">{error}</div>
              )}
              <form
                className="row form-style-ven document-img"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <h2>About Your Store & Documents</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <textarea
                    className="shadow-box border-0 w-100 p-2"
                    rows="10"
                    placeholder="Write here...."
                    onBlur={formik.handleBlur}
                    {...formik.getFieldProps("about")}
                  ></textarea>
                  {formik.touched.about && formik.errors.about ? (
                    <div className="text-red mt-2">{formik.errors.about}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6 mt-2">
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      <img
                        alt=""
                        src={formik.values.pan || "assets/images/doc-image.png"}
                      />
                      <p>Upload your Pan Card</p>
                    </button>
                    <Upload dataHandler={handleImage} />
                  </div>
                  {formik.touched.pan && formik.errors.pan ? (
                    <div className="text-red mt-2">{formik.errors.pan}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6 mt-2">
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      <img
                        alt=""
                        src={
                          formik.values.aadhar || "assets/images/doc-image.png"
                        }
                      />
                      <p>Upload Aadhar Proof</p>
                    </button>
                    <Upload dataHandler={handleAadharProof} />
                  </div>
                  {formik.touched.aadhar && formik.errors.aadhar ? (
                    <div className="text-red mt-2">{formik.errors.aadhar}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6 mt-2">
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      <img
                        alt=""
                        src={formik.values.gst || "assets/images/doc-image.png"}
                      />
                      <p>Upload GST Proof</p>
                    </button>
                    <Upload dataHandler={handleGstProof} />
                  </div>
                  {formik.touched.gst && formik.errors.gst ? (
                    <div className="text-red mt-2">{formik.errors.gst}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-6 col-md-6 col-lg-6 mt-2">
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      <img
                        alt=""
                        src={formik.values.id || "assets/images/doc-image.png"}
                      />
                      <p>Upload ID Proof</p>
                    </button>
                    <Upload dataHandler={handleIdProof} />
                  </div>
                  {formik.touched.id && formik.errors.id ? (
                    <div className="text-red mt-2">{formik.errors.id}</div>
                  ) : null}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mt-5">
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={
                        formik.values.gst === "" ||
                        formik.values.pan === "" ||
                        formik.values.aadhar === ""
                      }
                      className="btn btn-save-chenge mt-2"
                    >
                      Save & Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default AddVenderDocument;
