import { useState } from "react";
import Sidebar from "../Sidebar";
import { useFormik } from "formik";
import * as yup from "yup";
import SelectUserModal from "./SelectUserModal";
import { useDispatch } from "react-redux";
import { GetUserList } from "../User/UserSlice";
import { AddNotificationCall } from "./NotificationSlice";
import { useNavigate } from "react-router";

function AddNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState("");

  const handleShow = () => {
    setShow(!show);
  };

  const handleAddNotification = (data) => {
    console.log(data);
    dispatch(AddNotificationCall({ notification_data: data })).then((res) => {
      if (res?.payload?.code === 1) {
        navigate("/manage-notification", { replace: true });
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const submitLogin = () => {
    let data = formik.values.notification_data;
    const arr = data.map((item, index) => {
      return item;
    });

    handleAddNotification(arr);
  };

  const validationSchema = yup.object({
    notification_data: yup.array().of(
      yup.object().shape({
        subject: yup.string().required("Required."),
        message: yup.string().required("Required."),
        customer_ids: yup.array().min(1, "Required"),
        valueType: yup
          .string()
          .default("")
          .required("Kindly select the user to send the notification."),
      })
    ),
  });
  const formik = useFormik({
    initialValues: {
      notification_data: [
        {
          subject: "",
          message: "",
          customer_ids: [],
          valueType: "",
        },
      ],
    },

    validationSchema: validationSchema,
    onSubmit: submitLogin,
  });

  const getUsers = (i) => {
    dispatch(GetUserList({ status: "1" })).then((res) => {
      if (res?.payload?.code === 1) {
        let data = res?.payload?.data?.user;
        let arr = data.map((item, ind) => {
          return { id: item.id };
        });
        formik.setFieldValue(`notification_data[${i}.customer_ids]`, arr);
      }
    });
  };

  const onDoneHandler = (val) => {
    const newArr = val.map((item) => {
      return { id: item };
    });
    formik.setFieldValue(
      `notification_data.[${currentIndex}].customer_ids`,
      newArr
    );
  };
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="notification" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Write New Message</h2>
              {error !== "" && (
                <div className="alert alert-danger mt-2">{error}</div>
              )}
              <form
                className="form-subject-massege"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  formik.handleSubmit();
                }}
              >
                <div className="list-message-box">
                  {formik.values.notification_data?.length > 0 ? (
                    formik.values.notification_data?.map((item, i) => {
                      return (
                        <div className="student-details-box shadow-box">
                          <div className="form-group">
                            <div
                              style={{
                                position: "relative",
                              }}
                            >
                              <img
                                src="assets/images/delete.png"
                                alt=""
                                className="delIcon cursor-pointer"
                                onClick={() => {
                                  let arr = [
                                    ...formik.values.notification_data,
                                  ];
                                  arr.splice(i, 1);
                                  formik.setFieldValue(
                                    "notification_data",
                                    arr
                                  );
                                }}
                              />
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Subject"
                              onBlur={formik.handleBlur}
                              {...formik.getFieldProps(
                                `notification_data[${i}].subject`
                              )}
                            />
                            {formik.touched.notification_data?.[i]?.subject &&
                            formik?.errors.notification_data?.[i]?.subject ? (
                              <div className="text-red mt-2">
                                {
                                  formik?.errors?.notification_data?.[i]
                                    ?.subject
                                }
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              placeholder="Message"
                              rows="10"
                              onBlur={formik.handleBlur}
                              {...formik.getFieldProps(
                                `notification_data[${i}].message`
                              )}
                            ></textarea>
                            {formik.touched.notification_data?.[i]?.message &&
                            formik.errors.notification_data?.[i]?.message ? (
                              <div className="text-red mt-2">
                                {formik.errors.notification_data?.[i]?.message}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group nav noti-radio-tabs">
                            <div>
                              <input
                                id={`sent-all${i}`}
                                name={`notifcation_data[${i}].valueType`}
                                type="radio"
                                checked={
                                  formik.values.notification_data?.[i]
                                    .valueType === "all"
                                }
                                onChange={() => {
                                  formik.setFieldValue(
                                    `notification_data[${i}].valueType`,
                                    "all"
                                  );
                                  getUsers(i);
                                }}
                              />
                              <label htmlFor={`sent-all${i}`}>
                                Sent to All
                              </label>
                            </div>
                            <div>
                              <input
                                id={`select-user${i}`}
                                name={`notifcation_data[${i}].valueType`}
                                type="radio"
                                checked={
                                  formik.values.notification_data?.[i]
                                    .valueType === "users"
                                }
                                onChange={() => {
                                  handleShow();
                                  setCurrentIndex(i);
                                  formik.setFieldValue(
                                    `notification_data[${i}].valueType`,
                                    "users"
                                  );
                                }}
                              />
                              <label htmlFor={`select-user${i}`}>
                                Select users only
                              </label>
                            </div>
                          </div>
                          {formik.touched.notification_data?.[i]?.valueType ? (
                            <div className="text-red mt-2">
                              {formik.errors.notification_data?.[i]
                                ?.valueType ||
                                formik.errors.notification_data?.[i]
                                  ?.customer_ids}
                            </div>
                          ) : null}
                          <div className="form-group">
                            <div className="select-customer-total">
                              {
                                formik.values.notification_data?.[i]
                                  .customer_ids?.length
                              }{" "}
                              customers are selected
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No record found</div>
                  )}
                </div>
                <div className="form-group">
                  <span
                    id="btnAddtoList"
                    onClick={() => {
                      let arr = [...formik.values.notification_data];

                      arr.push({
                        subject: "",
                        message: "",
                        customer_ids: [],
                        valueType: "",
                      });
                      formik.setFieldValue("notification_data", arr);
                    }}
                  >
                    + Add Multiple Messages
                  </span>
                </div>
                <div className="form-group">
                  <div className="text-center">
                    <button type="submit" className="btn btn-save-chenge mt-2">
                      Send
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>

      {show && (
        <SelectUserModal
          show={show}
          handleShow={() => {
            handleShow();
          }}
          onDone={(val) => {
            onDoneHandler(val);
          }}
        />
      )}
    </div>
  );
}

export default AddNotification;
