import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Sidebar from "../Sidebar";
import { ChangeBookingStatus, GetBookingDetail } from "./BookingSlice";

function Booking() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.booking.detail);
  const status = useSelector((state) => state.booking.status);
  const [error, setError] = useState("");

  const getDetails = useCallback(() => {
    dispatch(GetBookingDetail({ id: id }));
  }, [dispatch, id]);

  const bookingStatus = (data) => {
    dispatch(ChangeBookingStatus(data)).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
          navigate("/manage-booking", { replace: true });
        }, 3000);
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }
    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="booking" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">
                {status === "0"
                  ? "New Booking "
                  : status === "1"
                  ? "Upcoming Booking "
                  : status === "4"
                  ? "Completed "
                  : "Booking "}
                Details
              </h2>

              <h3 className="mt-5">Customer Details</h3>
              <div className="student-details-box shadow-box Booking-Details">
                <span>
                  <img
                    alt=""
                    src={
                      data?.user_details?.image !== "" &&
                      data?.user_details?.image !== "url" &&
                      data?.user_details?.image !== "null"
                        ? data?.user_details?.image
                        : "assets/images/default-user.png"
                    }
                    className="img-fluid usr-img"
                  />
                </span>
                <span>
                  <h5>
                    {data?.user_details?.first_name
                      ? `${data?.user_details?.first_name}` +
                        " " +
                        `${data?.user_details?.last_name}`
                      : "NA"}
                  </h5>
                  <p>
                    <span>
                      <img alt="" src="assets/images/mobile.png" /> Mobile No:
                      {data?.user_details?.mobile
                        ? data?.user_details?.countryCode +
                          data?.user_details?.mobile
                        : "NA"}
                    </span>
                    <span>
                      <img alt="" src="assets/images/add-email.png" /> Email:{" "}
                      {data?.user_details?.email || "NA"}
                    </span>
                    {/* <span>
                      <img alt="" src="assets/images/address.png" /> Address:{" "}
                      {data?.user_details
                        ? data?.user_details.flat +
                          ", " +
                          data?.user_details?.society +
                          ", " +
                          data?.user_details?.city
                        : "NA"}
                    </span> */}
                  </p>
                </span>
                <span className="booking-date">
                  {moment(data?.date, "YYYY-MM-DD").format("DD MMM YYYY")}
                </span>
              </div>

              <h3 className="mt-5">Vendor Details</h3>
              <div className="student-details-box shadow-box Booking-Details">
                <span>
                  <img
                    alt=""
                    src={
                      data?.vendor_details?.logo !== "" &&
                      data?.vendor_details?.logo !== "url" &&
                      data?.vendor_details?.logo !== "null"
                        ? data?.vendor_details?.logo
                        : "assets/images/default-user.png"
                    }
                    className="img-fluid usr-img"
                  />
                </span>
                <span>
                  <h5>{`${data?.vendor_details?.full_name}`}</h5>
                  <p>
                    <span>
                      <img alt="" src="assets/images/mobile.png" /> Mobile No:{" "}
                      {data?.vendor_details?.mobile
                        ? data?.vendor_details?.countryCode +
                          data?.vendor_details?.mobile
                        : "NA"}
                    </span>
                    <span>
                      <img alt="" src="assets/images/add-email.png" /> Email:{" "}
                      {data?.vendor_details?.email || "NA"}
                    </span>
                  </p>
                  <p>
                    {" "}
                    <span>
                      <img alt="" src="assets/images/address.png" /> Address:{" "}
                      {data?.vendor_details?.address || "NA"}
                    </span>
                  </p>
                </span>
              </div>

              <h3 className="mt-5">Services</h3>
              <div className="row align-items-center">
                {data?.service_list?.length > 0 ? (
                  data?.service_list?.map((service) => {
                    return (
                      <>
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                          <div className="revenue-total d-flex justify-content-between">
                            <span className="text-capitalize">
                              {service?.name}
                            </span>
                            <span>
                              {service?.ITServiceDetails?.price && (
                                <i className="fa fa-inr" aria-hidden="true"></i>
                              )}
                              {service?.ITServiceDetails?.price &&
                                `${service?.ITServiceDetails?.price}/day`}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                          <h3 className="mt-5">Date & Time Slot</h3>
                          <div className="date-time-flex">
                            <span>
                              {moment(data?.date, "YYYY-MM-DD").format(
                                "DD MMM YYYY"
                              )}
                            </span>
                            {service?.ITServiceDetails?.no_of_days && " to "}
                            {service?.ITServiceDetails?.no_of_days ? (
                              <span>
                                {moment(data?.date, "YYYY-MM-DD")
                                  .add(
                                    "days",
                                    service?.ITServiceDetails?.no_of_days
                                  )
                                  .format("DD MMM YYYY")}
                              </span>
                            ) : service?.transportServiceDetails?.total_km ? (
                              `${service?.transportServiceDetails?.total_km}km`
                            ) : null}
                            <span className="vrt-line"></span>
                            <span>{data?.time_slot}</span>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                          <h3 className="mt-5">Payment Mode</h3>
                          <div className="date-time-flex justify-content-start">
                            <span>
                              {data?.payment_type === "1"
                                ? "Pay Now"
                                : "Pay Later"}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                          <h3 className="mt-5">Total Price</h3>
                          <div className="date-time-flex justify-content-start">
                            <span>
                              <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                              {service?.ITServiceDetails?.total_price
                                ? service?.ITServiceDetails?.total_price
                                : service?.transportServiceDetails?.total_price
                                ? service?.transportServiceDetails?.total_price
                                : data?.paymentDetails?.amount}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div className="col-sm-6 col-md-6 col-lg-6 mt-3">
                    {" "}
                    <div className="d-flex justify-content-between">
                      No services listed.
                    </div>
                  </div>
                )}
              </div>
              <h4 className="">Booking Details</h4>
              <div className="student-details-box shadow-box Booking-Details mt-0">
                <span>
                  <h5>{data?.bookingAddress?.full_name || "NA"}</h5>
                  <p>
                    <span>
                      <img alt="" src="assets/images/address.png" /> Address:{" "}
                      {data?.bookingAddress
                        ? data?.bookingAddress.flat +
                          ", " +
                          data?.bookingAddress?.society +
                          ", " +
                          data?.bookingAddress?.city
                        : "NA"}
                    </span>
                  </p>
                </span>
                <span className="booking-date">
                  {moment(data?.date, "YYYY-MM-DD").format("DD MMM YYYY")}
                </span>
              </div>
              {/* <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                  <h3 className="mt-5">Date & Time Slot</h3>
                  <div className="date-time-flex">
                    <span>
                      {moment(data?.date, "YYYY-MM-DD").format("DD MMM YYYY")}
                    </span>
                    <span className="vrt-line"></span>
                    <span>{data?.time_slot}</span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                  <h3 className="mt-5">Payment Mode</h3>
                  <div className="date-time-flex justify-content-start">
                    <span>
                      {data?.payment_type === "1" ? "Pay Now" : "Pay Later"}
                    </span>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                  <h3 className="mt-5">Total Price</h3>
                  <div className="date-time-flex justify-content-start">
                    <span>
                      <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                      {data?.paymentDetails?.amount || "NA"}
                    </span>
                  </div>
                </div>
              </div> */}

              {data?.status === "0" && (
                <div className="btn-lv-app cnl-svcng">
                  <button
                    type="button"
                    className="btn btn-save-chenge appp mt-2"
                    onClick={() => {
                      bookingStatus({
                        bookingId: data?.id,
                        status: "1",
                      });
                    }}
                  >
                    Accept
                  </button>
                  <button
                    className="btn btn-cancel mt-2"
                    type="button"
                    onClick={() => {
                      bookingStatus({
                        bookingId: data?.id,
                        status: "2",
                      });
                    }}
                  >
                    <img alt="" src="assets/images/reject-button.png" />
                  </button>
                  {/* <button className="btn btn-cancel mt-2">
                    <img alt="" src="assets/images/delete-button.png" />
                  </button> */}
                </div>
              )}
              {error && (
                <div class="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default Booking;
