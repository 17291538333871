import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function PrivateRoutes({ children }) {
  const isAuthorized = localStorage.getItem("token");
  // const isAuthorized = localStorage.getItem("token");
  if (isAuthorized) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
}

export default PrivateRoutes;
