import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../Modal/DeleteModal";
import Sidebar from "../Sidebar";
import { DeletePlan, PlanList, TogglePlan } from "./PlanSlice";

function SubscriptionPlan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const data = useSelector((state) => state.plan.list);
  const total = useSelector((state) => state.plan.totalPlan);
  const [delId, setDelId] = useState("");
  const handleShow = () => {
    setShow(!show);
  };

  const confirmDelete = () => {
    dispatch(DeletePlan({ id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res?.payload?.message);
        handleShow();
        getList();
        setTimeout(() => {
          setError("");
        }, 3000);
      } else {
        setError(res?.payload?.message);
        handleShow();
        getList();
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  const getList = () => {
    dispatch(PlanList()).then((res) => {});
  };

  const handleToggle = (data) => {
    dispatch(TogglePlan(data)).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res?.payload?.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 3000);
      } else {
        setError(res?.payload?.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };

  const handleDelete = (data) => {
    setDelId(data);
    handleShow();
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }
    return () => {
      isRendered = false;
    };
  }, []);

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="subscription" />

          <article className="col-lg-9">
            <div className="article-body">
              <div className="row align-items-center">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  <ul className="nav nav-tabs text-center group-tabs">
                    <li className="nav-item Subscription-box">
                      <a
                        className="nav-link revenue-total"
                        data-toggle="tab"
                        href="#total_student"
                      >
                        <p>
                          <img
                            alt=""
                            src="assets/images/Total-Subscription-Plan.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{total}</h5>
                        <p>Total Subscription</p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div className="d-flex align-items-center justify-content-end">
                    <Link to="/add-subscription" className="add-new">
                      + Add New
                    </Link>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                {error !== "" && (
                  <div className="col-sm-12">
                    <div className="alert alert-danger mt-2">{error}</div>
                  </div>
                )}
                <div className="col-sm-12">
                  <div className="tab-content">
                    <div id="total_student" className="tab-pane active">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">photo</th>
                              <th scope="col">subscription name</th>
                              <th scope="col">features</th>
                              <th scope="col">price</th>
                              <th scope="col">validity</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item, key) => {
                                return (
                                  <tr key={key}>
                                    <td>
                                      <img
                                        alt=""
                                        src="assets/images/Standard-Plan.png"
                                        className="table-img"
                                      />
                                    </td>
                                    <td>{item?.name}</td>
                                    <td>
                                      {item?.features?.length > 0
                                        ? item?.features
                                            ?.map((feat) => {
                                              return feat?.features;
                                            })
                                            .join(", ")
                                        : "NA"}
                                    </td>
                                    <td>
                                      <img
                                        alt=""
                                        src="assets/images/currency.png"
                                      />{" "}
                                      {item?.price}
                                    </td>
                                    <td>{moment(item?.validity).fromNow()}</td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button className="btn">
                                          <Link
                                            to={`/view-subscription/${item.id}`}
                                            title="View"
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn" title="Edit">
                                          <Link
                                            to={`/edit-subscription/${item.id}`}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/edit.png"
                                            />
                                          </Link>
                                        </button>
                                        <button className="btn">
                                          <span
                                            onClick={() => {
                                              handleDelete(item.id);
                                            }}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                        <button className="btn">
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={item.isActive}
                                            onChange={() => {
                                              handleToggle({
                                                planId: item.id,
                                                status: !item.isActive,
                                              });
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={6}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        {delId && (
          <DeleteModal
            show={show}
            page="Subscription Plan"
            setShow={handleShow}
            confirmDelete={confirmDelete}
          />
        )}
      </div>
    </div>
  );
}

export default SubscriptionPlan;
