import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ResetPasswordCall } from "./SettingSlice";

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [error, setError] = useState("");

  const submit = () => {
    dispatch(ResetPasswordCall({ password: formik.values.newPassword })).then(
      (res) => {
        if (res?.payload?.code === 1) {
          console.log(res);

          setError(res?.payload?.message);
          setTimeout(() => {
            setError("");
            navigate("/dashboard", { replace: true });
          }, 2000);
        } else {
          setError(res?.payload?.message);
          setTimeout(() => {
            setError("");
          }, 2000);
        }
      }
    );
  };

  const validationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    passwordConfirmation: yup
      .string()
      .required("Kindly confirm you new password.")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: submit,
  });
  return (
    <section className="">
      <div className="container-fluid pl-0 ml-0">
        <div className="row align-items-center">
          <div className="col-sm-6 col-md-6 col-lg-6 text-center ml-0 pl-0 sm-mp-lr">
            <div className="logo-bg">
              <div className="">
                <img
                  src="assets/images/image.png"
                  className="img-fluid w-100"
                  alt="logo"
                />
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6 col-lg-6">
            <form className="row login-form" onSubmit={formik.handleSubmit}>
              <div className="form-group col-sm-12 col-md-12 text-center mb-1">
                <div className="text-center">
                  <img
                    alt=""
                    src="assets/images/Reset-Password.png"
                    className="forgot-icon modl-icn"
                  />
                </div>
                <h2 className="mdl-ttl">Reset Password</h2>
                <p className="mdl-sbttl">Please reset your password here.</p>
              </div>
              <div className="col-sm-12">
                {error !== "" && (
                  <div className="alert alert-danger mt-2">{error}</div>
                )}
              </div>
              <div className="form-group col-sm-12 col-md-12 mb-4 mt-4">
                <div className="input-container">
                  <input
                    type={toggle1 ? "text" : "password"}
                    className="form-control pl-5"
                    placeholder="Your New Password"
                    onBlur={formik.handleBlur}
                    {...formik.getFieldProps("newPassword")}
                  />
                  <img
                    alt=""
                    src="assets/images/Password.png"
                    className="input-img pass"
                  />
                  <span
                    toggle="#password-field"
                    onClick={() => {
                      setToggle1(!toggle1);
                    }}
                    className="pass-view field-icon toggle-password cursor-pointer"
                  ></span>
                </div>
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="alert alert-danger mt-2">
                    {formik.errors.newPassword}
                  </div>
                ) : null}
              </div>
              <div className="form-group col-sm-12 col-md-12 mb-4">
                <div className="input-container">
                  <input
                    type={toggle2 ? "text" : "password"}
                    className="form-control pl-5"
                    placeholder="Your Confirm New Password"
                    onBlur={formik.handleBlur}
                    {...formik.getFieldProps("passwordConfirmation")}
                  />
                  <img
                    alt=""
                    src="assets/images/Password.png"
                    className="input-img pass"
                  />
                  <span
                    toggle="#password-field"
                    onClick={() => {
                      setToggle2(!toggle2);
                    }}
                    className="pass-view field-icon toggle-password cursor-pointer"
                  ></span>
                </div>
                {formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation ? (
                  <div className="alert alert-danger mt-2">
                    {formik.errors.passwordConfirmation}
                  </div>
                ) : null}
              </div>
              <div className="form-group col-sm-12 col-md-12 text-center">
                <button type="submit" className="btn btn-submit">
                  Done
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
