import { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  AddVendorAvailabilityCall,
  GetTimeSlot,
  GetVenderDetail,
  GetWeekDays,
} from "./VenderSlice";
import { useSelector } from "react-redux";
import TimePicker from "react-time-picker";

const availabilityModal = {
  sun: {
    day: "",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  mon: {
    day: "",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  tue: {
    day: "",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  wed: {
    day: "",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  thur: {
    day: "",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  fri: {
    day: "",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
  sat: {
    day: "",
    open_time: "",
    close_time: "",
    status: 0,
    time_slot: [],
  },
};
function EditVenderAvailability() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [error, setError] = useState("");
  const [dayAvailable, setDayAvailable] = useState(availabilityModal);
  const daySlot = useSelector((state) => state.vender.weekDays);

  const submit = (e) => {
    e.preventDefault();

    let arr = [];
    let dataToSend = dayAvailable;

    for (const key in dataToSend) {
      if (Object.hasOwnProperty.call(dataToSend, key)) {
        const element = dataToSend[key];
        arr.push(element);
      }
    }

    let checked = arr.some(
      (item) =>
        item.status === 1 && item.open_time !== "" && item.close_time !== ""
    );

    if (checked) {
      dispatch(
        AddVendorAvailabilityCall({
          available_data: arr,
          vendorId: id,
        })
      ).then((res) => {
        if (res?.payload?.code === 1) {
          navigate(`/edit-vender-documents/${id}`);
        } else {
          setError(res?.payload?.message);
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      });
    } else {
      setError("Kindly fill all details.");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  const getTime = useCallback(() => {
    dispatch(GetTimeSlot());
  }, [dispatch]);

  const getDays = useCallback(() => {
    dispatch(GetWeekDays());
  }, [dispatch]);

  const handleTimeSlot = (val, index, cIndex, day) => {
    let arr = [...dayAvailable[day].time_slot];

    let newArr = arr.map((data, i) => {
      if (i === cIndex) {
        data.time = val.target.value;
      }
      return data;
    });

    setDayAvailable({
      ...dayAvailable,
      [day]: {
        ...dayAvailable[day],
        time_slot: newArr,
      },
    });
  };

  const handleDeleteTime = (pIndex, cIndex, day) => {
    let arr = [...dayAvailable[day].time_slot];

    arr.map((t, i) => {
      if (i === cIndex) {
        arr.splice(i, 1);
      }
      return t;
    });

    setDayAvailable({
      ...dayAvailable,
      [day]: {
        ...dayAvailable[day],

        time_slot: arr,
      },
    });
  };

  const handleChange = (data) => {
    const { value, name, day } = data;
    setDayAvailable({
      ...dayAvailable,
      [day]: {
        ...dayAvailable[day],
        day: day,
        [name]: value,
      },
    });
  };

  const getDetails = useCallback(() => {
    dispatch(GetVenderDetail({ id: id })).then((res) => {
      if (res?.payload?.code === 1) {
        let dt = res?.payload?.data?.vendor_availability;
        let arr = { ...dayAvailable };
        dt.map((item) => {
          arr = {
            ...arr,
            [item.day]: {
              open_time: item.open_time,
              close_time: item.close_time,
              day: item.day,
              status: item.isActive === true ? 1 : 0,
              time_slot: item.time_slot_list,
            },
          };
          return arr;
        });
        setDayAvailable(arr);
      }
    });
  }, [dispatch, id]);

  useEffect(() => {
    let isRendered = false;
    if (!isRendered) {
      getTime();
      getDays();
      getDetails();
    }
    return () => {
      isRendered = false;
    };
  }, [getTime, getDays, getDetails]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Edit Vendor</h2>
              {error && <div className="alert alert-danger mt-2">{error}</div>}

              <form className="row form-style-ven">
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <h2>Availability</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="table-responsive">
                    <table className="table table-borderless">
                      <tr>
                        <th>Days</th>
                        <th>Open at</th>
                        <th>Close at</th>
                        <th></th>
                        <th>Add Time Slot</th>
                      </tr>
                      {daySlot?.map((days, i) => {
                        return (
                          <tr key={i}>
                            <td>{days?.day}</td>
                            <td>
                              <input
                                type="time"
                                className="time-drop border-0 shadow rounded-pill p-2"
                                value={
                                  dayAvailable[days?.day?.toLowerCase()]
                                    ?.open_time === "00:00:00"
                                    ? null
                                    : dayAvailable[days?.day?.toLowerCase()]
                                        .open_time
                                }
                                onChange={(val) => {
                                  handleChange({
                                    day: days?.day?.toLowerCase(),
                                    value: val.target.value,
                                    name: "open_time",
                                  });
                                }}
                                aria-describedby="basic-addon1"
                              />
                            </td>
                            <td>
                              <input
                                type="time"
                                className="time-drop border-0 shadow rounded-pill p-2"
                                value={
                                  dayAvailable[days?.day?.toLowerCase()]
                                    ?.close_time === "00:00:00"
                                    ? null
                                    : dayAvailable[days?.day?.toLowerCase()]
                                        .close_time
                                }
                                onChange={(val) => {
                                  handleChange({
                                    day: days?.day?.toLowerCase(),
                                    value: val.target.value,
                                    name: "close_time",
                                  });
                                }}
                                aria-describedby="basic-addon1"
                              />
                            </td>
                            <td>
                              <button type="button" className="btn">
                                <input
                                  type="checkbox"
                                  className="toggle mt-0"
                                  checked={
                                    dayAvailable[days?.day?.toLowerCase()]
                                      ?.status === 1
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleChange({
                                      day: days?.day?.toLowerCase(),
                                      value: e.target.checked === true ? 1 : 0,
                                      name: "status",
                                    });
                                  }}
                                />
                              </button>
                            </td>
                            <td>
                              <div className="form-group col-sm-12 col-md-6 col-lg-6 mt-2">
                                <ul className="services-checkbox time-slot">
                                  {dayAvailable[
                                    days?.day?.toLowerCase()
                                  ]?.time_slot?.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        style={{
                                          position: "relative",
                                          marginRight: "15px",
                                        }}
                                      >
                                      <input
                                type="time"
                                className="time-drop border-0 shadow rounded-pill p-2"
                                value={item.time}
                                onChange={(val) => {
                                  if (val !== undefined) {
                                    let d = days?.day?.toLowerCase();
                                    handleTimeSlot(val, i, index, d);
                                  }
                                }}
                                aria-describedby="basic-addon1"
                              />



                                       
                                        <img
                                          alt=""
                                          src={"assets/images/delete.png"}
                                          className="deleteIcon"
                                          onClick={() => {
                                            handleDeleteTime(
                                              i,
                                              index,
                                              days.day
                                            );
                                          }}
                                        />
                                      </li>
                                    );
                                  })}

                                  <li>
                                    <a
                                      href={() => {
                                        return "a";
                                      }}
                                      onClick={() => {
                                        let arr = [
                                          ...dayAvailable[
                                            days?.day?.toLowerCase()
                                          ].time_slot,
                                        ];
                                        arr.push({
                                          time: "",
                                        });
                                        setDayAvailable({
                                          ...dayAvailable,
                                          [days.day]: {
                                            ...dayAvailable[days.day],
                                            time_slot: arr,
                                          },
                                        });
                                        
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src="assets/images/upload.png"
                                      />
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12">
                  <div className="text-center">
                    <span>
                      <button
                        type="button"
                        onClick={(e) => {
                          submit(e);
                        }}
                        className="btn btn-save-chenge mt-2"
                      >
                        Save & Next
                      </button>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default EditVenderAvailability;
