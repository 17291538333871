import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Constant";

const initialState = {
  list: [],
  total: 0,
  detail: null,
};

export const GetNotificationList = createAsyncThunk(
  "notification list",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(
      `${BASE_URL}notification/getAdminNotificationList`,

      {
        params: {
          page: obj.page,
          limit: 10,
          search: obj.search,
        },
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const AddNotificationCall = createAsyncThunk(
  "notification add",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.post(
      `${BASE_URL}notification/addAdminNotification`,
      obj,
      {
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const GetNotificationDetail = createAsyncThunk(
  "notification detail",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.get(
      `${BASE_URL}notification/getAdminNotificationDetails`,
      {
        params: {
          id: obj.id,
        },
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const DeleteNotification = createAsyncThunk(
  "DeleteNotification",
  async (obj, { getState }) => {
    const token = localStorage.getItem("token");

    const response = await axios.delete(
      `${BASE_URL}notification/deleteNotification`,
      {
        params: {
          notificationId: obj.id,
        },
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const ToggleNotification = createAsyncThunk(
  "ToggleNotification",
  async (obj, { getState }) => {
    const token = await localStorage.getItem("token");

    let formData = new FormData();

    for (let key in obj) {
      formData.append(key, obj[key]);
    }

    const response = await axios.patch(
      `${BASE_URL}notification/updateNotificationStatus`,
      formData,
      {
        headers: {
          token,
        },
      }
    );
    return response.data;
  }
);

export const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [GetNotificationList.fulfilled]: (state, action) => {
      console.log(action);
      state.list = action?.payload?.data?.notification_list;
      state.total = action?.payload?.data?.total_notification;
    },
    [GetNotificationDetail.fulfilled]: (state, action) => {
      console.log(action);
      state.detail = action?.payload?.data?.notification_details;
    },
  },
});

export default NotificationSlice.reducer;
