import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Sidebar from "../Sidebar";
import { GetNotificationDetail } from "./NotificationSlice";

function ViewNotification() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const detail = useSelector((state) => state?.notification?.detail);

  const getDetails = useCallback(() => {
    dispatch(GetNotificationDetail({ id: id }));
  }, [dispatch, id]);

  useEffect(() => {
    let isRendered = false;
    if (!isRendered) {
      getDetails();
    }
    return () => {
      isRendered = false;
    };
  }, [getDetails]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="notification" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <h2 className="text-center">Message Details</h2>
              <div className="student-details-box shadow-box">
                <span className="date-time-noti">
                  <span>
                    Date: {moment(detail?.createdAt).format("DD MMM YYYY")}
                  </span>
                  <span>
                    Time: {moment(detail?.createdAt).format("hh:mm a")}
                  </span>
                </span>
                <div className="subject-messa noti-sub-top">
                  <span className="sub-mm">Subject:</span>
                  <span>{detail?.subject}</span>
                </div>
                <div className="subject-messa">
                  <span className="sub-mm">Message:</span>
                  <span>{detail?.message}</span>
                </div>
              </div>

              <div className="Rejected-Booking-text d-flex justify-content-between align-items-center">
                <h3 className="mt-5">Sent to</h3>
                <span className="mt-5 font-weight-600">Selected Customers</span>
              </div>
              <div className="student-details-box shadow-box">
                <div className="notification-sent">
                  {detail?.customer_list?.map((item, i) => {
                    return (
                      <div className="">
                        <img
                          alt=""
                          src={
                            item?.userDetails?.image?.includes("http")
                              ? item?.userDetails?.image
                              : "assets/images/default-user.png"
                          }
                        />{" "}
                        {item?.userDetails?.first_name
                          ? item?.userDetails?.first_name +
                            " " +
                            item?.userDetails?.last_name
                          : ""}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default ViewNotification;
