import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Constant";

const initialState = {
  list: [],
  totalPlan: 0,
  planData: {},
};

export const PlanList = createAsyncThunk("plan list", async (obj) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`${BASE_URL}vendor/planList`, {
    headers: {
      token,
    },
  });

  return response.data;
});

export const PlanDetail = createAsyncThunk("plan detail", async (obj) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`${BASE_URL}vendor/getPlanDetails`, {
    params: { planId: obj.id },
    headers: {
      token,
    },
  });

  return response.data;
});

export const DeletePlan = createAsyncThunk("delete plan", async (obj) => {
  const token = localStorage.getItem("token");
  const response = await axios.delete(`${BASE_URL}admin/deletePlan`, {
    params: { planId: obj.id },
    headers: {
      token,
    },
  });

  return response.data;
});

export const DeleteFeature = createAsyncThunk("delete feature", async (obj) => {
  const token = localStorage.getItem("token");
  const response = await axios.delete(`${BASE_URL}admin/removePlanFeature`, {
    params: { planId: obj.id, featureId: obj.fId },
    headers: {
      token,
    },
  });

  return response.data;
});

export const TogglePlan = createAsyncThunk("delete plan", async (obj) => {
  const token = localStorage.getItem("token");

  let formData = new FormData();

  for (var key in obj) {
    formData.append(key, obj[key]);
  }

  const response = await axios.patch(
    `${BASE_URL}admin/updatePlanStatus`,
    formData,
    {
      headers: {
        token,
      },
    }
  );

  return response.data;
});

export const AddPlan = createAsyncThunk("AddPlan", async (obj) => {
  const token = localStorage.getItem("token");

  let formData = new FormData();

  for (var key in obj) {
    if (typeof obj[key] === "object") {
      formData.append(key, JSON.stringify(obj[key]));
    } else {
      formData.append(key, obj[key]);
    }
  }

  const response = await axios.post(`${BASE_URL}admin/addPlan`, formData, {
    headers: {
      token,
    },
  });

  return response.data;
});

export const PlanSlice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    reducerName: (state, action) => {},
  },
  extraReducers: {
    [PlanList.fulfilled]: (state, action) => {
      state.list = action?.payload?.data?.plan;
      state.totalPlan = action?.payload?.data?.total_plan;
    },
    [PlanDetail.fulfilled]: (state, action) => {
      console.log(action);
      state.planData = action?.payload?.data?.plan_details;
    },
  },
});

export default PlanSlice.reducer;
