import React from "react";
import { Modal } from "react-bootstrap";

function DeleteModal({ show, setShow, confirmDelete, page }) {
  const modalForDelete = () => {
    return (
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="deleteModalLabel">
              Delete {page}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShow(!show);
              }}
            >
              X
            </button>
          </div>
          <div className="modal-body">
            Do you really want to delete the {page} ?
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                setShow(!show);
              }}
            >
              Close
            </button>
            <button
              type="button"
              onClick={confirmDelete}
              className="btn btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };
  return <Modal show={show} onHide={setShow} dialogAs={modalForDelete} />;
}

export default DeleteModal;
