import React, { useState, useEffect, useCallback } from "react";
import Pagination from "react-js-pagination";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../Modal/DeleteModal";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteService,
  DeleteSubService,
  GetServicesDetail,
  GetSubServicesDetail,
  GetSubServicesList,
  UpdateServicesStatus,
} from "./ServicesSlice";
import ViewSubServices from "../../Modal/ViewSubServices";

function ManageSubServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const total = useSelector((state) => state.services.subTotal);
  const [activePage, setActivePage] = useState(1);
  const [show, setShow] = useState(false);
  const data = useSelector((state) => state.services.subList);
  const [viewShow, setViewShow] = useState(false);
  const detail = useSelector((state) => state.services.detail);

  const [delId, setDelId] = useState("");

  const Paginate = (val) => {
    setActivePage(val);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const confirmDelete = () => {
    dispatch(DeleteService({ id: delId })).then((res) => {
      if (res?.payload?.code === 1) {
        handleShow();
        setError(res.payload.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  const handleDelete = (val) => {
    setDelId(val);
    handleShow();
  };

  const getList = useCallback(() => {
    dispatch(GetSubServicesList({ page: activePage, search: searchValue }));
  }, [activePage, searchValue, dispatch]);

  const handleViewShow = useCallback(() => {
    setViewShow(!viewShow);
  }, [viewShow]);

  const getDetails = useCallback(
    (viewId) => {
      dispatch(GetServicesDetail({ id: viewId })).then((res) => {
        if (res?.payload?.code === 1) {
          handleViewShow();
        }
      });
    },
    [dispatch, handleViewShow]
  );

  const handleToggle = (data) => {
    dispatch(UpdateServicesStatus(data)).then((res) => {
      if (res?.payload?.code === 1) {
        setError(res.payload.message);
        getList();
        setTimeout(() => {
          setError("");
        }, 2000);
      } else {
        setError(res?.payload?.message);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    });
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getList();
    }
    return () => {
      isRendered = false;
    };
  }, [getList]);
  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="sub-services" />

          <article className="col-lg-9">
            <div className="article-body">
              <div className="row align-items-center col-rever">
                <div className="col-sm-4 col-md-6 col-lg-6">
                  <ul className="nav nav-tabs text-center group-tabs">
                    <li className="nav-item Subscription-box">
                      <a
                        className="nav-link revenue-total"
                        data-toggle="tab"
                        href="#total_student"
                      >
                        <p>
                          <img
                            alt=""
                            src="assets/images/Total-Services.png"
                            className="img-fluid"
                          />
                        </p>
                        <h5>{total && total}</h5>
                        <p>Total Sub-Services</p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-6">
                  <div className="d-flex align-items-center lg-justify-content-end">
                    <form className="has-search">
                      <span className="form-search-icon form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search sub-service by name"
                        value={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                      />
                    </form>
                    <Link to="/add-sub-services" className="add-new">
                      + Add New
                    </Link>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12">
                  {error !== "" && (
                    <div className="alert alert-danger mt-2">{error}</div>
                  )}
                </div>
                <div className="col-sm-12">
                  <div className="tab-content">
                    <div id="total_student" className="tab-pane active">
                      <div className="table-responsive text-center">
                        <table className="table storetable">
                          <thead className="text-center">
                            <tr>
                              <th scope="col">photo</th>
                              <th scope="col">sub-service</th>
                              <th scope="col">price</th>
                              <th scope="col">services name</th>
                              <th scope="col">action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.length > 0 ? (
                              data.map((item) => {
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        alt=""
                                        src={
                                          item.image.includes("http")
                                            ? item.image
                                            : "assets/images/default-services.png"
                                        }
                                        className="table-img"
                                      />
                                    </td>
                                    <td>{item.name || "NA"}</td>
                                    <td>
                                      <img
                                        alt=""
                                        src="assets/images/currency.png"
                                      />{" "}
                                      {item.price || "0"}
                                    </td>
                                    <td>{item?.parentServiceDetails?.name}</td>
                                    <td>
                                      <div className="d-flex justify-content-center action">
                                        <button
                                          className="btn"
                                          data-toggle="modal"
                                          data-target="#viewsubservice"
                                        >
                                          <span
                                            onClick={() => {
                                              getDetails(item.id);
                                            }}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/view-pro.png"
                                            />
                                          </span>
                                        </button>
                                        <button className="btn">
                                          <Link
                                            to={`/edit-sub-service/${item.id}`}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/edit.png"
                                            />
                                          </Link>
                                        </button>
                                        <button
                                          className="btn"
                                          onClick={() => {
                                            setShow(!show);
                                          }}
                                        >
                                          <span
                                            onClick={() => {
                                              handleDelete(item.id);
                                            }}
                                          >
                                            <img
                                              alt=""
                                              src="assets/images/delete.png"
                                            />
                                          </span>
                                        </button>
                                        <button className="btn">
                                          <input
                                            type="checkbox"
                                            className="toggle"
                                            checked={item?.status}
                                            onChange={() => {
                                              handleToggle({
                                                service_id: item.id,
                                                status: !item.status,
                                              });
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={5}>No data found.</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {data?.length > 0 && (
                        <div className="col-sm-12">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Number(total)}
                            pageRangeDisplayed={3}
                            onChange={Paginate}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      {show && (
        <DeleteModal
          show={show}
          setShow={handleShow}
          page="Sub-Service"
          confirmDelete={confirmDelete}
        />
      )}

      {detail && (
        <ViewSubServices
          viewShow={viewShow}
          setViewShow={handleViewShow}
          data={detail}
        />
      )}
    </div>
  );
}

export default ManageSubServices;
