import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Sidebar from "../Sidebar";
import { GetVenderDetail } from "./VenderSlice";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
function VenderDetail() {
  const handle = useFullScreenHandle();
  const handle1 = useFullScreenHandle();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector((state) => state.vender.vendorDetail);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const getDetails = () => {
    dispatch(GetVenderDetail({ id: id }));
  };

  useEffect(() => {
    let isRendered = false;

    if (!isRendered) {
      getDetails();
    }
    return () => {
      isRendered = false;
    };
  }, []);

  return (
    <div className="admin-panel">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <Sidebar page="vender" />

          <article className="col-lg-9">
            <div className="article-body store-bd-gy">
              <div className="text-center">
                <h2>Vendor Details</h2>
              </div>
              <div className="student-details-box shadow-box text-center">
                <div className="table-responsive">
                  <table className="table table-borderless">
                    <tr>
                      <td>
                        <div className="student-img">
                          <img
                            alt=""
                            src={
                              data?.vendor?.logo ||
                              "assets/images/default-user.png"
                            }
                            style={{
                              height: 70,
                              width: 70,
                              borderRadius: 50,
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <p>
                          <img alt="" src="assets/images/add-Vendors.png" />
                        </p>
                        <p>
                          <b>full name</b>
                        </p>
                        <p>{data?.vendor?.full_name || "NA"}</p>
                      </td>
                      <td>
                        <p>
                          <img alt="" src="assets/images/add-email.png" />
                        </p>
                        <p>
                          <b>email address</b>
                        </p>
                        <p className="text-lowercase">
                          {data?.vendor?.email || "NA"}
                        </p>
                      </td>
                      <td>
                        <p>
                          <img alt="" src="assets/images/mobile.png" />
                        </p>
                        <p>
                          <b>mobile no.</b>
                        </p>
                        <p>
                          {data?.vendor?.mobile
                            ? data?.vendor?.countryCode + data?.vendor?.mobile
                            : "NA"}
                        </p>
                      </td>
                      <td>
                        <p>
                          <img alt="" src="assets/images/address.png" />
                        </p>
                        <p>
                          <b>address</b>
                        </p>
                        <p>{data?.vendor?.address || "NA"}</p>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <h2 className="mt-5">Services</h2>
              <div className="row">
                {data?.vendor_services?.length > 0 ? (
                  data?.vendor_services?.map((item, i) => {
                    return (
                      <div className="col-sm-12 col-md-4 col-lg-4 mt-3">
                        <div className="services-box shadow-box">
                          <div className="ser-name-img">
                            <img
                              alt=""
                              src={
                                item?.parent_image.includes("http")
                                  ? item?.parent_image
                                  : "assets/images/default-services.png"
                              }
                              style={{
                                height: 50,
                                width: 50,
                                borderRadius: 50,
                                marginRight: 5,
                              }}
                              className="img-fluid"
                            />
                            <span>{item?.parent_name || ""}</span>
                          </div>
                          <hr />
                          {item?.services?.map((data, index) => {
                            return (
                              <div className="ser-dtl" key={index}>
                                <span>{data?.name}</span>
                                <span>
                                  <img
                                    alt=""
                                    src="assets/images/currency.png"
                                  />{" "}
                                  {data?.price}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="col-sm-12">No services added.</div>
                )}
              </div>
              <h2 className="mt-5">Availability</h2>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                  <div className="table-responsive">
                    {/* <table className="table storetable Availability-table">
                      <thead className="">
                        <tr>
                          <th scope="col">Days</th>
                          <th scope="col">Opens at</th>
                          <th scope="col">Close at</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody className="bg-white shadow-box">
                        <tr>
                          <td>Sun</td>
                          <td>12:00pm</td>
                          <td>8:00pm</td>
                        </tr>
                      </tbody>
                    </table> */}
                    <table class="table storetable Availability-table">
                      <thead class="">
                        <tr>
                          <th scope="col">Days</th>
                          <th scope="col">Opens at</th>
                          <th scope="col">Close at</th>
                          <th scope="col"></th>
                          <th scope="col">Time Slots</th>
                        </tr>
                        <tr class="border-0 bg-none invisible">
                          <td class="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody class="bg-white shadow-box">
                        {data?.vendor_availability?.length > 0 ? (
                          data?.vendor_availability
                            ?.filter((fd) => fd?.isActive)
                            .map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item?.day || ""}</td>
                                  <td>
                                    {item.open_time
                                      ? moment(item?.open_time, "hh:mm").format(
                                          "hh:mm a"
                                        )
                                      : "NA"}
                                  </td>
                                  <td>
                                    {item.close_time
                                      ? moment(
                                          item?.close_time,
                                          "hh:mm"
                                        ).format("hh:mm a")
                                      : "NA"}
                                  </td>
                                  <td></td>
                                  <td>
                                    <div class="time-slot-grid">
                                      {item.time_slot_list?.map((time, ind) => {
                                        return (
                                          <span key={ind}>
                                            {time?.time
                                              ? moment(
                                                  time?.time,
                                                  "hh:mm"
                                                ).format("hh:mm a")
                                              : "NA"}
                                          </span>
                                        );
                                      })}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td>No data found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <h2 className="mt-5">About Store & Documents</h2>
              <p>{data?.documents?.about_store}</p>
              <div className="row id-proof-img mb-3">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                  <h5>Pan Card</h5>
                  <FullScreen
                    handle={handle}
                    onChange={(state, handle) => {
                      setIsFullScreen(state);
                    }}
                  >
                    <img
                      alt=""
                      src={
                        data?.documents?.pan_card ||
                        "assets/images/dummy-img.png"
                      }
                      className={isFullScreen ? "h-100 img-fluid" : "img-fluid"}
                      onClick={() => {
                        setIsFullScreen(!isFullScreen);
                        handle.enter();
                      }}
                    />
                  </FullScreen>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                  <h5>GST Proof</h5>
                  <FullScreen
                    handle={handle1}
                    onChange={(state, handle) => {
                      setIsFullScreen(state);
                    }}
                  >
                    <img
                      alt=""
                      src={
                        data?.documents?.gst_proof ||
                        "assets/images/dummy-img.png"
                      }
                      className={isFullScreen ? "h-100 img-fluid" : "img-fluid"}
                      onClick={() => {
                        setIsFullScreen(!isFullScreen);
                        handle.enter();
                      }}
                    />
                  </FullScreen>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                  <h5>Aadhar Card</h5>
                  <FullScreen
                    handle={handle}
                    onChange={(state, handle) => {
                      setIsFullScreen(state);
                    }}
                  >
                    <img
                      alt=""
                      src={
                        data?.documents?.aadhar_proof ||
                        "assets/images/dummy-img.png"
                      }
                      className={isFullScreen ? "h-100 img-fluid" : "img-fluid"}
                      onClick={() => {
                        setIsFullScreen(!isFullScreen);
                        handle.enter();
                      }}
                    />
                  </FullScreen>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-3">
                  <h5>Id Proof</h5>
                  <FullScreen
                    handle={handle1}
                    onChange={(state, handle) => {
                      setIsFullScreen(state);
                    }}
                  >
                    <img
                      alt=""
                      src={
                        data?.documents?.id_proof ||
                        "assets/images/dummy-img.png"
                      }
                      className={isFullScreen ? "h-100 img-fluid" : "img-fluid"}
                      onClick={() => {
                        setIsFullScreen(!isFullScreen);
                        handle.enter();
                      }}
                    />
                  </FullScreen>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default VenderDetail;
