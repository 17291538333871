import React from "react";
import S3 from "react-aws-s3";
import { uid } from "uid";
import { Buffer } from "buffer";
window.Buffer = Buffer;

function Upload({ dataHandler }) {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  };
  const QID = uid();

  const ReactS3Client = new S3(config);

  const newFileName = QID;

  const handleClick = (event) => {
    ReactS3Client.uploadFile(event.target.files[0], newFileName)
      .then((data) => {
        console.log(data, "data from s3");
        dataHandler(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <input
      type="file"
      name="myfile"
      accept="image/*"
      onChange={(event) => {
        console.log(event, "file");
        handleClick(event);
      }}
    />
  );
}

export default Upload;
